<template>
  <div class="index_wrap">
    <h1 class="h_none">商标查询|专利翻译|知识产权管理系统-蓝灯鱼智能检索平台</h1>
    <h2 class="h_none">蓝灯鱼,lanternfish,蓝灯鱼商标,蓝灯鱼翻译,蓝灯鱼专利,AI,人工智能</h2>
    <img src="/images/result_show_logo.png" alt="" class="h_none">
    <div style="height: 90px"></div>
    <div class="title">
      <div class="title_content">
        <img src="../../assets/images/services/star1.png" alt="">
        <span>购买享专属优惠</span>
        <img src="../../assets/images/services/star1.png" alt="">
      </div>
      <div class="title_sub">
        请选择适合您的会员及套餐
      </div>
      <div class="tabBtn">
        <span @click="tabEvent(item)" :class="[{active: item.id == tabId}]" :key="index" v-for="(item,index) in tabList">{{item.name}}</span>
      </div>
    </div>
<!--    <div class="describe">-->
<!--      基础版、标准版、专业版等多种模式，满足不同用户需求，更专业、更快速、更智能-->
<!--    </div>-->
    <div v-if="tabId == 1" :class="['vipBox', {active1: active == 1}, {active2: active == 2}, {active3: active == 3}, {active4: active == 4},]" >
      <div  class="tab">
        <span @click="tabVip(1)"  :class="{'active': active == 1}">商标版</span>
        <span @click="tabVip(2)" :class="{'active': active == 2}">专利版</span>
        <span @click="tabVip(3)" :class="{'active': active == 3}">联合版</span>
        <span @click="tabVip(4)" :class="{'active': active == 4}">托管平台</span>
      </div>
      <!--    商标-->
      <div v-if="active == 1" class="trademark">
        <ul class="trademark_content">
          <li @click="toPay(trademarkProductServiceManages[0], 0)" :class="['trademark_li',{'active': indexs == 0}]">
            <div class="trademark_content_header">
              <span class="titles">{{trademarkProductServiceManages[0].cardName}}</span>
              <p>购买服务可享<span>5</span><span>折</span>优惠</p>
            </div>
            <div class="trademark_content_item">
              <p class="price"> <i>¥</i><span>{{trademarkProductServiceManages[0].productPrice}}</span><i>/</i><i>年</i></p>
              <p class="limt">支持<span>1</span>个用户登录</p>
              <div class="payBtn">立即购买</div>
              <p class="vip"><span class="line"></span> <i>最高可省6144元</i> <span class="line"></span></p>
              <ul class="content">
                <li>
                  <span>中国大陆商标查询<i ></i></span>
                  <span>5折</span>
                </li>
                <li>
                  <span>全球查询<i >100次</i></span>
                  <span>5折</span>
                </li>
                <li>
                  <span>全球查询<i >2000次</i></span>
                  <span>5折</span>
                </li>
                <li>
                  <span>全球查询<i >15000次</i></span>
                  <span>5折</span>
                </li>
                <li>
                  <span>全球商标监控<i ></i></span>
                  <span>5折</span>
                </li>
              </ul>
              <p class="vip"><span class="line active"></span> <i>会员额外赠</i> <span class="line active"></span></p>
              <ul class="content">
                <li>
                  <span>全球商标监控<i ></i></span>
                  <span>10次</span>
                </li>
                <li>
                  <span>驳通智写-驳回复审<i ></i></span>
                  <span>10篇</span>
                </li>
                <li>
                  <span>驳通智写-驳文转达<i ></i></span>
                  <span>10篇</span>
                </li>
                <li>
                  <span>异议智写<i ></i></span>
                  <span>10篇</span>
                </li>
              </ul>
              <div class="bottomright">
                <img src="../../assets/images/services/selected.png" alt="">
              </div>
            </div>
          </li>
          <li @click="toPay(trademarkProductServiceManages[1], 1)" :class="['trademark_li',{'active': indexs == 1}]">
            <div class="trademark_content_header">
              <span class="titles">{{trademarkProductServiceManages[1].cardName}}</span>
              <p>购买服务可享<span>3</span><span>折</span>优惠</p>
            </div>
            <div class="trademark_content_item">
              <p class="price"><i>¥</i><span>{{trademarkProductServiceManages[1].productPrice}}</span><i>/</i><i>年</i></p>
              <p class="limt">支持<span>2</span>个用户登录</p>
              <div class="payBtn">立即购买</div>
              <p class="vip"><span class="line"></span> <i>最高可省10772元</i> <span class="line"></span></p>
              <ul class="content">
                <li>
                  <span>中国大陆商标查询<i ></i></span>
                  <span>3折</span>
                </li>
                <li>
                  <span>全球查询<i >100次</i></span>
                  <span>3折</span>
                </li>
                <li>
                  <span>全球查询<i >2000次</i></span>
                  <span>3折</span>
                </li>
                <li>
                  <span>全球查询<i >15000次</i></span>
                  <span>3折</span>
                </li>
                <li>
                  <span>全球商标监控<i ></i></span>
                  <span>3折</span>
                </li>
              </ul>
              <p class="vip"><span class="line active"></span> <i>会员额外赠</i> <span class="line active"></span></p>
              <ul class="content">
                <li>
                  <span>全球商标监控<i ></i></span>
                  <span>30次</span>
                </li>
                <li>
                  <span>驳通智写-驳回复审<i ></i></span>
                  <span>30篇</span>
                </li>
                <li>
                  <span>驳通智写-驳文转达<i ></i></span>
                  <span>30篇</span>
                </li>
                <li>
                  <span>异议智写<i ></i></span>
                  <span>30篇</span>
                </li>
              </ul>
              <div class="bottomright">
                <img src="../../assets/images/services/selected.png" alt="">
              </div>
            </div>
          </li>
          <li @click="toPay(trademarkProductServiceManages[2], 2)" :class="['trademark_li',{'active': indexs == 2}]">
            <div class="tuijian">
              超值推荐
            </div>
            <div class="trademark_content_header">
              <span class="titles">{{trademarkProductServiceManages[2].cardName}}</span>
              <p>购买服务可享<span>2</span><span>折</span>优惠</p>
            </div>
            <div class="trademark_content_item">
              <p class="price"><i>¥</i><span>{{trademarkProductServiceManages[2].productPrice}}</span><i>/</i><i>年</i></p>
              <p class="limt">支持<span>5</span>个用户登录</p>
              <div class="payBtn">立即购买</div>
              <p class="vip"><span class="line"></span> <i>最高可省31499元</i> <span class="line"></span></p>
              <ul class="content">
                <li>
                  <span>全球查询<i >100次</i></span>
                  <span>2折</span>
                </li>
                <li>
                  <span>全球查询<i >2000次</i></span>
                  <span>2折</span>
                </li>
                <li>
                  <span>全球查询<i >15000次</i></span>
                  <span>2折</span>
                </li>
                <li>
                  <span>全球商标监控<i ></i></span>
                  <span>2折</span>
                </li>
              </ul>
              <p class="vip"><span class="line active"></span> <i>会员额外赠</i> <span class="line active"></span></p>
              <ul class="content">
                <li>
                  <span>中国大陆商标查询<i ></i></span>
                  <span>免费</span>
                </li>
                <li>
                  <span>全球商标监控<i ></i></span>
                  <span>50次</span>
                </li>
                <li>
                  <span>驳通智写-驳回复审<i ></i></span>
                  <span>50篇</span>
                </li>
                <li>
                  <span>驳通智写-驳文转达<i ></i></span>
                  <span>50篇</span>
                </li>
                <li>
                  <span>异议智写<i ></i></span>
                  <span>50篇</span>
                </li>
              </ul>
              <div class="bottomright">
                <img src="../../assets/images/services/selected.png" alt="">
              </div>
            </div>
          </li>
          <li @click="toPay(trademarkProductServiceManages[3], 3)" :class="['trademark_li',{'active': indexs == 3}]">
            <div class="trademark_content_header">
              <span class="titles">{{trademarkProductServiceManages[3].cardName}}</span>
              <p>购买服务可享<span>1</span><span>折</span>优惠</p>
            </div>
            <div class="trademark_content_item">
              <p class="price"><i>¥</i><span>{{trademarkProductServiceManages[3].productPrice}}</span><i>/</i><i>年</i></p>
              <p class="limt">支持<span>10</span>个用户登录</p>
              <div class="payBtn">立即购买</div>
              <p class="vip"><span class="line"></span> <i>最高可省121099元</i> <span class="line"></span></p>
              <ul class="content">
                <li>
                  <span>中国大陆商标查询<i ></i></span>
                  <span>1折</span>
                </li>
                <li>
                  <span>全球查询<i >100次</i></span>
                  <span>1折</span>
                </li>
                <li>
                  <span>全球查询<i >2000次</i></span>
                  <span>1折</span>
                </li>
                <li>
                  <span>全球商标监控<i ></i></span>
                  <span>1折</span>
                </li>
              </ul>
              <p class="vip"><span class="line active"></span> <i>会员额外赠</i> <span class="line active"></span></p>
              <ul class="content">
                <li>
                  <span>全球查询<i >15000次</i></span>
                  <span>免费</span>
                </li>
                <li>
                  <span>全球商标监控<i ></i></span>
                  <span>100次</span>
                </li>
                <li>
                  <span>驳通智写-驳回复审<i ></i></span>
                  <span>100篇</span>
                </li>
                <li>
                  <span>驳通智写-驳文转达<i ></i></span>
                  <span>100篇</span>
                </li>
                <li>
                  <span>异议智写<i ></i></span>
                  <span>100篇</span>
                </li>
              </ul>
              <div class="bottomright">
                <img src="../../assets/images/services/selected.png" alt="">
              </div>
            </div>
          </li>
        </ul>
      </div>

      <!--    专利-->
      <div v-if="active == 2" class="trademark">
        <ul class="trademark_content">
          <li @click="toPay(patentProductServiceManages[0], 4)" :class="['trademark_li',{'active': indexs == 4}]">
            <div class="trademark_content_header">
              <span class="titles">{{patentProductServiceManages[0].cardName}}</span>
              <p>购买服务可享<span>5</span><span>折</span>优惠</p>
            </div>
            <div class="trademark_content_item">
              <p class="price"><i>¥</i><span>{{patentProductServiceManages[0].productPrice}}</span><i>/</i><i>年</i></p>
              <p class="limt">支持<span>1</span>个用户登录</p>
              <div class="payBtn">立即购买</div>
              <p class="vip"><span class="line"></span> <i>最高可省35196元</i> <span class="line"></span></p>
              <ul class="content">
                <li>
                  <span>专利翻译<i ></i></span>
                  <span>5折</span>
                </li>
                <li>
                  <span>专利五书拆分<i ></i></span>
                  <span>5折</span>
                </li>
                <li>
                  <span>CPC官文提取（单用户）<i ></i></span>
                  <span>5折</span>
                </li>
                <li>
                  <span>CPC官文提取（多用户）<i ></i></span>
                  <span>5折</span>
                </li>
                <li>
                  <span>专利撰写形式核查助手<i ></i></span>
                  <span>5折</span>
                </li>
              </ul>
              <p class="vip"><span class="line active"></span> <i>会员额外赠</i> <span class="line active"></span></p>
              <ul class="content">
                <li>
                  <span>专利附图一键压缩<i ></i></span>
                  <span>免费</span>
                </li>
                <li>
                  <span>专利AI-OA精灵<i ></i></span>
                  <span>50篇</span>
                </li>
                <li>
                  <span>专利AI-芯智写<i ></i></span>
                  <span>50篇</span>
                </li>
              </ul>
              <div class="bottomright">
                <img src="../../assets/images/services/selected.png" alt="">
              </div>
            </div>
          </li>
          <li @click="toPay(patentProductServiceManages[1], 5)" :class="['trademark_li',{'active': indexs == 5}]">
            <div class="trademark_content_header">
              <span class="titles">{{patentProductServiceManages[1].cardName}}</span>
              <p>购买服务可享<span>3</span><span>折</span>优惠</p>
            </div>
            <div class="trademark_content_item">
              <p class="price"><i>¥</i><span>{{patentProductServiceManages[1].productPrice}}</span><i>/</i><i>年</i></p>
              <p class="limt">支持<span>2</span>个用户登录</p>
              <div class="payBtn">立即购买</div>
              <p class="vip"><span class="line"></span> <i>最高可省188604元</i> <span class="line"></span></p>
              <ul class="content">
                <li>
                  <span>专利翻译<i ></i></span>
                  <span>3折</span>
                </li>
                <li>
                  <span>CPC官文提取（单用户）<i ></i></span>
                  <span>3折</span>
                </li>
                <li>
                  <span>CPC官文提取（多用户）<i ></i></span>
                  <span>3折</span>
                </li>
                <li>
                  <span>专利撰写形式核查助手<i ></i></span>
                  <span>3折</span>
                </li>

              </ul>
              <p class="vip"><span class="line active"></span> <i>会员额外赠</i> <span class="line active"></span></p>
              <ul class="content">
                <li>
                  <span>专利五书拆分<i ></i></span>
                  <span>免费</span>
                </li>
                <li>
                  <span>专利附图一键压缩<i ></i></span>
                  <span>免费</span>
                </li>
                <li>
                  <span>专利AI-OA精灵<i ></i></span>
                  <span>200篇</span>
                </li>
                <li>
                  <span>专利AI-芯智写<i ></i></span>
                  <span>200篇</span>
                </li>
              </ul>
              <div class="bottomright">
                <img src="../../assets/images/services/selected.png" alt="">
              </div>
            </div>
          </li>
          <li @click="toPay(patentProductServiceManages[2], 6)" :class="['trademark_li',{'active': indexs == 6}]">
            <div class="tuijian">
              超值推荐
            </div>
            <div class="trademark_content_header">
              <span class="titles">{{patentProductServiceManages[2].cardName}}</span>
              <p>购买服务可享<span>2</span><span>折</span>优惠</p>
            </div>
            <div class="trademark_content_item">
              <p class="price"><i>¥</i><span>{{patentProductServiceManages[2].productPrice}}</span><i>/</i><i>年</i></p>
              <p class="limt">支持<span>5</span>个用户登录</p>
              <div class="payBtn">立即购买</div>
              <p class="vip"><span class="line"></span> <i>最高可省540392元</i> <span class="line"></span></p>
              <ul class="content">
                <li>
                  <span>专利翻译<i ></i></span>
                  <span>2折</span>
                </li>
              </ul>
              <p class="vip"><span class="line active"></span> <i>会员额外赠</i> <span class="line active"></span></p>
              <ul class="content">
                <li>
                  <span>专利五书拆分<i ></i></span>
                  <span>免费</span>
                </li>
                <li>
                  <span>专利附图一键压缩<i ></i></span>
                  <span>免费</span>
                </li>
                <li>
                  <span>CPC官文提取（单用户）<i ></i></span>
                  <span>免费</span>
                </li>
                <li>
                  <span>CPC官文提取（多用户）<i ></i></span>
                  <span>免费</span>
                </li>
                <li>
                  <span>专利撰写形式核查助手<i ></i></span>
                  <span>免费</span>
                </li>
                <li>
                  <span>专利AI-OA精灵<i ></i></span>
                  <span>500篇</span>
                </li>
                <li>
                  <span>专利AI-芯智写<i ></i></span>
                  <span>500篇</span>
                </li>
              </ul>
              <div class="bottomright">
                <img src="../../assets/images/services/selected.png" alt="">
              </div>
            </div>
          </li>
          <li @click="toPay(patentProductServiceManages[3], 7)" :class="['trademark_li',{'active': indexs == 7}]">
            <div class="trademark_content_header">
              <span class="titles">{{patentProductServiceManages[3].cardName}}</span>
              <p>购买服务可享<span>1</span><span>折</span>优惠</p>
            </div>
            <div class="trademark_content_item">
              <p class="price"><i>¥</i><span>{{patentProductServiceManages[3].productPrice}}</span><i>/</i><i>年</i></p>
              <p class="limt">支持<span>10</span>个用户登录</p>
              <div class="payBtn">立即购买</div>
              <p class="vip"><span class="line"></span> <i>最高可省2434392元</i> <span class="line"></span></p>
              <ul class="content">
                <li>
                  <span>专利翻译<i ></i></span>
                  <span>1折</span>
                </li>
              </ul>
              <p class="vip"><span class="line active"></span> <i>会员额外赠</i> <span class="line active"></span></p>
              <ul class="content">
                <li>
                  <span>专利五书拆分<i ></i></span>
                  <span>免费</span>
                </li>
                <li>
                  <span>专利附图一键压缩<i ></i></span>
                  <span>免费</span>
                </li>
                <li>
                  <span>CPC官文提取（单用户）<i ></i></span>
                  <span>免费</span>
                </li>
                <li>
                  <span>CPC官文提取（多用户）<i ></i></span>
                  <span>免费</span>
                </li>
                <li>
                  <span>专利撰写形式核查助手<i ></i></span>
                  <span>免费</span>
                </li>
                <li>
                  <span>专利AI-OA精灵<i ></i></span>
                  <span>2000篇</span>
                </li>
                <li>
                  <span>专利AI-芯智写<i ></i></span>
                  <span>2000篇</span>
                </li>
              </ul>
              <div class="bottomright">
                <img src="../../assets/images/services/selected.png" alt="">
              </div>
            </div>
          </li>
        </ul>
      </div>

      <!--    联合-->
      <div v-if="active == 3" :class="['trademark', {'active3': active ==3}]">
        <ul class="trademark_content">
          <li @click="toPay(trialProductServiceManages[0], 8)" :class="['trademark_li',{'active': indexs == 8}]">
            <div class="trademark_content_header">
              <span class="titles">{{trialProductServiceManages[0].cardName}}</span>
              <p>购买服务可享<span>5</span><span>折</span>优惠</p>
            </div>
            <div class="trademark_content_item">
              <p class="price"><i>¥</i><span>{{trialProductServiceManages[0].productPrice}}</span><i>/</i><i>年</i></p>
              <p class="limt">支持<span>1</span>个用户登录</p>
              <div class="payBtn">立即购买</div>
              <p class="vip"><span class="line"></span> <i>最高可省40847元</i> <span class="line"></span></p>
              <ul class="content">
                <li>
                  <span>中国大陆商标查询<i ></i></span>
                  <span>5折</span>
                </li>
                <li>
                  <span>全球查询<i >100次</i></span>
                  <span>5折</span>
                </li>
                <li>
                  <span>全球查询<i >2000次</i></span>
                  <span>5折</span>
                </li>
                <li>
                  <span>全球查询<i >15000次</i></span>
                  <span>5折</span>
                </li>
                <li>
                  <span>全球商标监控<i ></i></span>
                  <span>5折</span>
                </li>
                <li>
                  <span>专利翻译<i ></i></span>
                  <span>5折</span>
                </li>
                <li>
                  <span>专利五书拆分<i ></i></span>
                  <span>5折</span>
                </li>
                <li>
                  <span>CPC官文提取（单用户）<i ></i></span>
                  <span>5折</span>
                </li>
                <li>
                  <span>CPC官文提取（多用户）<i ></i></span>
                  <span>5折</span>
                </li>
                <li>
                  <span>专利撰写形式核查助手<i ></i></span>
                  <span>5折</span>
                </li>
              </ul>
              <p class="vip"><span class="line active"></span> <i>会员额外赠</i> <span class="line active"></span></p>
              <ul class="content">
                <li>
                  <span>全球商标监控<i ></i></span>
                  <span>10次</span>
                </li>
                <li>
                  <span>驳通智写-驳回复审<i ></i></span>
                  <span>10篇</span>
                </li>
                <li>
                  <span>驳通智写-驳文转达<i ></i></span>
                  <span>10篇</span>
                </li>
                <li>
                  <span>异议智写<i ></i></span>
                  <span>10篇</span>
                </li>
                <li>
                  <span>专利翻译<i ></i></span>
                  <span>10W字</span>
                </li>
                <li>
                  <span>专利附图一键压缩<i ></i></span>
                  <span>免费</span>
                </li>
                <li>
                  <span>专利AI-OA精灵<i ></i></span>
                  <span>50篇</span>
                </li>
                <li>
                  <span>专利AI-芯智写<i ></i></span>
                  <span>50篇</span>
                </li>
              </ul>
              <div class="bottomright">
                <img src="../../assets/images/services/selected.png" alt="">
              </div>
            </div>
          </li>
          <li @click="toPay(trialProductServiceManages[1], 9)" :class="['trademark_li',{'active': indexs == 9}]">
            <div class="trademark_content_header">
              <span class="titles">{{trialProductServiceManages[1].cardName}}</span>
              <p>购买服务可享<span>3</span><span>折</span>优惠</p>
            </div>
            <div class="trademark_content_item">
              <p class="price"><i>¥</i><span>{{trialProductServiceManages[1].productPrice}}</span><i>/</i><i>年</i></p>
              <p class="limt">支持<span>2</span>个用户登录</p>
              <div class="payBtn">立即购买</div>
              <p class="vip"><span class="line"></span> <i>最高可省201084元</i> <span class="line"></span></p>
              <ul class="content">
                <li>
                  <span>中国大陆商标查询<i ></i></span>
                  <span>3折</span>
                </li>
                <li>
                  <span>全球查询<i >100次</i></span>
                  <span>3折</span>
                </li>
                <li>
                  <span>全球查询<i >2000次</i></span>
                  <span>3折</span>
                </li>
                <li>
                  <span>全球查询<i >15000次</i></span>
                  <span>3折</span>
                </li>
                <li>
                  <span>全球商标监控<i ></i></span>
                  <span>3折</span>
                </li>
                <li>
                  <span>专利翻译<i ></i></span>
                  <span>3折</span>
                </li>
                <li>
                  <span>CPC官文提取（单用户）<i ></i></span>
                  <span>3折</span>
                </li>
                <li>
                  <span>CPC官文提取（多用户）<i ></i></span>
                  <span>3折</span>
                </li>
              </ul>
              <p class="vip"><span class="line active"></span> <i>会员额外赠</i> <span class="line active"></span></p>
              <ul class="content">
                <li>
                  <span>全球商标监控<i ></i></span>
                  <span>20次</span>
                </li>
                <li>
                  <span>驳通智写-驳回复审<i ></i></span>
                  <span>20篇</span>
                </li>
                <li>
                  <span>驳通智写-驳文转达<i ></i></span>
                  <span>20篇</span>
                </li>
                <li>
                  <span>异议智写<i ></i></span>
                  <span>20篇</span>
                </li>
                <li>
                  <span>专利翻译<i ></i></span>
                  <span>50W字</span>
                </li>
                <li>
                  <span>专利五书拆分<i ></i></span>
                  <span>免费</span>
                </li>
                <li>
                  <span>专利附图一键压缩<i ></i></span>
                  <span>免费</span>
                </li>
                <li>
                  <span>专利撰写形式核查助手<i ></i></span>
                  <span>免费</span>
                </li>
                <li>
                  <span>专利AI-OA精灵<i ></i></span>
                  <span>200篇</span>
                </li>
                <li>
                  <span>专利AI-芯智写<i ></i></span>
                  <span>200篇</span>
                </li>
              </ul>
              <div class="bottomright">
                <img src="../../assets/images/services/selected.png" alt="">
              </div>
            </div>
          </li>
          <li @click="toPay(trialProductServiceManages[2], 10)" :class="['trademark_li',{'active': indexs == 10}]">
            <div class="tuijian">
              超值推荐
            </div>
            <div class="trademark_content_header">
              <span class="titles">{{trialProductServiceManages[2].cardName}}</span>
              <p>购买服务可享<span>2</span><span>折</span>优惠</p>
            </div>
            <div class="trademark_content_item">
              <p class="price"><i>¥</i><span>{{trialProductServiceManages[2].productPrice}}</span><i>/</i><i>年</i></p>
              <p class="limt">支持<span>5</span>个用户登录</p>
              <div class="payBtn">立即购买</div>
              <p class="vip"><span class="line"></span> <i>最高可省574381元</i> <span class="line"></span></p>
              <ul class="content">
                <li>
                  <span>全球查询<i >100次</i></span>
                  <span>2折</span>
                </li>
                <li>
                  <span>全球查询<i >2000次</i></span>
                  <span>2折</span>
                </li>
                <li>
                  <span>全球查询<i >15000次</i></span>
                  <span>2折</span>
                </li>
                <li>
                  <span>全球商标监控<i ></i></span>
                  <span>2折</span>
                </li>
                <li>
                  <span>专利翻译<i ></i></span>
                  <span>2折</span>
                </li>
                <li>
                  <span>CPC官文提取（单用户）<i ></i></span>
                  <span>2折</span>
                </li>
                <li>
                  <span>CPC官文提取（多用户）<i ></i></span>
                  <span>2折</span>
                </li>
              </ul>
              <p class="vip"><span class="line active"></span> <i>会员额外赠</i> <span class="line active"></span></p>
              <ul class="content">
                <li>
                  <span>中国大陆商标查询<i ></i></span>
                  <span>免费</span>
                </li>
                <li>
                  <span>全球商标监控<i ></i></span>
                  <span>50次</span>
                </li>
                <li>
                  <span>驳通智写-驳回复审<i ></i></span>
                  <span>50篇</span>
                </li>
                <li>
                  <span>驳通智写-驳文转达<i ></i></span>
                  <span>50篇</span>
                </li>
                <li>
                  <span>异议智写<i ></i></span>
                  <span>50篇</span>
                </li>
                <li>
                  <span>专利翻译<i ></i></span>
                  <span>200W字</span>
                </li>
                <li>
                  <span>专利五书拆分<i ></i></span>
                  <span>免费</span>
                </li>
                <li>
                  <span>专利附图一键压缩<i ></i></span>
                  <span>免费</span>
                </li>
                <li>
                  <span>专利撰写形式核查助手<i ></i></span>
                  <span>免费</span>
                </li>
                <li>
                  <span>专利AI-OA精灵<i ></i></span>
                  <span>500篇</span>
                </li>
                <li>
                  <span>专利AI-芯智写<i ></i></span>
                  <span>500篇</span>
                </li>
              </ul>
              <div class="bottomright">
                <img src="../../assets/images/services/selected.png" alt="">
              </div>
            </div>
          </li>
          <li @click="toPay(trialProductServiceManages[3], 11)" :class="['trademark_li',{'active': indexs == 11}]">
            <div class="trademark_content_header">
              <span class="titles">{{trialProductServiceManages[3].cardName}}</span>
              <p>购买服务可享<span>1</span><span>折</span>优惠</p>
            </div>
            <div class="trademark_content_item">
              <p class="price"><i>¥</i><span>{{trialProductServiceManages[3].productPrice}}</span><i>/</i><i>年</i></p>
              <p class="limt">支持<span>10</span>个用户登录</p>
              <div class="payBtn">立即购买</div>
              <p class="vip"><span class="line"></span> <i>最高可省2558430元</i> <span class="line"></span></p>
              <ul class="content">
                <li>
                  <span>中国大陆商标查询<i ></i></span>
                  <span>1折</span>
                </li>
                <li>
                  <span>全球查询<i >100次</i></span>
                  <span>1折</span>
                </li>
                <li>
                  <span>全球查询<i >2000次</i></span>
                  <span>1折</span>
                </li>


                <li>
                  <span>全球商标监控<i ></i></span>
                  <span>1折</span>
                </li>
                <li>
                  <span>专利翻译<i ></i></span>
                  <span>1折</span>
                </li>
                <li>
                  <span>CPC官文提取（单用户）<i ></i></span>
                  <span>1折</span>
                </li>
                <li>
                  <span>CPC官文提取（多用户）<i ></i></span>
                  <span>1折</span>
                </li>
              </ul>
              <p class="vip"><span class="line active"></span> <i>会员额外赠</i> <span class="line active"></span></p>
              <ul class="content">
                <li>
                  <span>全球查询<i >15000次</i></span>
                  <span>免费</span>
                </li>
                <li>
                  <span>全球商标监控<i ></i></span>
                  <span>200次</span>
                </li>
                <li>
                  <span>驳通智写-驳回复审<i ></i></span>
                  <span>200篇</span>
                </li>
                <li>
                  <span>驳通智写-驳文转达<i ></i></span>
                  <span>200篇</span>
                </li>
                <li>
                <span>异议智写<i ></i></span>
                <span>200篇</span>
              </li>
                <li>
                  <span>专利翻译<i ></i></span>
                  <span>1000W字</span>
                </li>
                <li>
                  <span>专利五书拆分<i ></i></span>
                  <span>免费</span>
                </li>
                <li>
                  <span>专利附图一键压缩<i ></i></span>
                  <span>免费</span>
                </li>
                <li>
                  <span>专利撰写形式核查助手<i ></i></span>
                  <span>免费</span>
                </li>
                <li>
                  <span>专利AI-OA精灵<i ></i></span>
                  <span>2000篇</span>
                </li>
                <li>
                  <span>专利AI-芯智写<i ></i></span>
                  <span>2000篇</span>
                </li>
              </ul>
              <div class="bottomright">
                <img src="../../assets/images/services/selected.png" alt="">
              </div>
            </div>
          </li>
        </ul>
      </div>
      <!--    托管-->
      <div v-if="active == 4" :class="['custody', {'active4': active == 4}]">
        <ul class="trademark_content">
          <li @click="toPay(custodyProductServiceManages[0], 12)" :class="['trademark_li',{'active': indexs == 12}]">
            <div class="trademark_content_header">
              <span class="titles">{{custodyProductServiceManages[0].productName}}</span>
            </div>
            <div class="trademark_content_item">
              <p class="price"><i>¥</i><span>{{custodyProductServiceManages[0].price}}</span><i>/</i><i>年</i></p>
              <p class="vip"><span class="line active"></span> <i>VIP权益</i> <span class="line active"></span></p>
              <ul class="content">
                <li>
                  <span>托管时间<i ></i></span>
                  <span>一年</span>
                </li>
                <li>
                  <span>托管申请人<i ></i></span>
                  <span>1个</span>
                </li>
                <li>
                  <span>商标/专利托管量<i ></i></span>
                  <span>10条</span>
                </li>
                <li>
                  <span>软著/资质托管量<i ></i></span>
                  <span>不限</span>
                </li>
              </ul>
              <div class="payBtn">立即购买</div>
              <div class="bottomright">
                <img src="../../assets/images/services/selected.png" alt="">
              </div>
            </div>
          </li>
          <li @click="toPay(custodyProductServiceManages[1], 13)" :class="['trademark_li',{'active': indexs == 13}]">
            <div class="trademark_content_header">
              <span class="titles">{{custodyProductServiceManages[1].productName}}</span>
            </div>
            <div class="trademark_content_item">
              <p class="price"><i>¥</i><span>{{custodyProductServiceManages[1].price}}</span><i>/</i><i>年</i></p>
              <p class="vip"><span class="line active"></span> <i>VIP权益</i> <span class="line active"></span></p>
              <ul class="content">
                <li>
                  <span>托管时间<i ></i></span>
                  <span>一年</span>
                </li>
                <li>
                  <span>托管申请人<i ></i></span>
                  <span>1个</span>
                </li>
                <li>
                  <span>商标/专利托管量<i ></i></span>
                  <span>20条</span>
                </li>
                <li>
                  <span>软著/资质托管量<i ></i></span>
                  <span>不限</span>
                </li>
              </ul>
              <div class="payBtn">立即购买</div>
              <div class="bottomright">
                <img src="../../assets/images/services/selected.png" alt="">
              </div>
            </div>
          </li>
          <li @click="toPay(custodyProductServiceManages[2], 14)" :class="['trademark_li',{'active': indexs == 14}]">
            <div class="trademark_content_header">
              <span class="titles">{{custodyProductServiceManages[2].productName}}</span>
            </div>
            <div class="trademark_content_item">
              <p class="price"><i>¥</i><span>{{custodyProductServiceManages[2].price}}</span><i>/</i><i>年</i></p>
              <p class="vip"><span class="line active"></span> <i>VIP权益</i> <span class="line active"></span></p>
              <ul class="content">
                <li>
                  <span>托管时间<i ></i></span>
                  <span>一年</span>
                </li>
                <li>
                  <span>托管申请人<i ></i></span>
                  <span>3个</span>
                </li>
                <li>
                  <span>商标/专利托管量<i ></i></span>
                  <span>50条</span>
                </li>
                <li>
                  <span>软著/资质托管量<i ></i></span>
                  <span>不限</span>
                </li>
              </ul>
              <div class="payBtn">立即购买</div>
              <div class="bottomright">
                <img src="../../assets/images/services/selected.png" alt="">
              </div>
            </div>
          </li>
          <li @click="toPay(custodyProductServiceManages[3], 15)" :class="['trademark_li',{'active': indexs == 15}]">
            <div class="trademark_content_header">
              <span class="titles">{{custodyProductServiceManages[3].productName}}</span>
            </div>
            <div class="trademark_content_item">
              <p class="price"><i>¥</i><span>{{custodyProductServiceManages[3].price}}</span><i>/</i><i>年</i></p>
              <p class="vip"><span class="line active"></span> <i>VIP权益</i> <span class="line active"></span></p>
              <ul class="content">
                <li>
                  <span>托管时间<i ></i></span>
                  <span>一年</span>
                </li>
                <li>
                  <span>托管申请人<i ></i></span>
                  <span>5个</span>
                </li>
                <li>
                  <span>商标/专利托管量<i ></i></span>
                  <span>200条</span>
                </li>
                <li>
                  <span>软著/资质托管量<i ></i></span>
                  <span>不限</span>
                </li>
              </ul>
              <div class="payBtn">立即购买</div>
              <div class="bottomright">
                <img src="../../assets/images/services/selected.png" alt="">
              </div>
            </div>
          </li>
        </ul>
      </div>


    </div>
    <div v-if="tabId == 1 && active != 4" class="equityComparison">
      <p class="equityComparison_title">
        <span>详情权益对比</span>
      </p>
      <vxe-grid
        ref='xGrid'
        header-row-class-name="vxe_equityComparison_header"
        :cell-class-name="cellClassName"
        :row-class-name="tableRowClassName"
        :highlight-hover-row="true"
        v-bind="gridOptions">
        <template #originalPrice = "{row}">
          <span>{{row.originalPrice}}</span>
          <span>{{row.unit}}</span>
        </template>
        <template #gold = "{row}">
          <span>{{row.gold}}</span>
          <span>{{row.unit}}</span>
        </template>
        <template #white = "{row}">
          <span>{{row.white}}</span>
          <span>{{row.unit}}</span>
        </template>
        <template #platinum = "{row}">
          <span>{{row.platinum}}</span>
          <span>{{row.unit}}</span>
        </template>
        <template #black = "{row}">
          <span>{{row.black}}</span>
          <span>{{row.unit}}</span>
        </template>
      </vxe-grid>
    </div>
    <div class="equityComparisonful" v-if="tabId == 2">
      <vxe-grid
        header-row-class-name="vxe_equityComparison_header"
        :cell-class-name="cellClassNames"
        :row-class-name="tableRowClassNames"
        :highlight-hover-row="true"
        height="920px"
        ref='fuelBag'
        v-bind="gridOptionss"
        @cell-click="cellClick"
        @checkbox-change="isChecked"
        @checkbox-all="isCheckeds"
      >
        <template #productName = "{row}">
          <div style="display: flex;justify-content: start;align-items: center;text-align: left;cursor: pointer">
            <span style="margin-right: 20px">{{row.productName}}</span> <i v-if="row.productName.indexOf('查询') != -1 || row.productName.indexOf('商标监控') != -1" :class="[{'el-icon-arrow-down': !row.arrow}, {'el-icon-arrow-up': row.arrow}]"></i>
          </div>
        </template>
        <template #productPrice = "{row}">
          <span>{{row.productPrice}}</span>
          <span v-if="row.productName.indexOf('AI') != -1 || row.productName.indexOf('智写') != -1">元/篇</span>
          <span v-else-if="row.productName.indexOf('商标监控') != -1">元/个</span>
          <span v-else-if="row.productName.indexOf('专利翻译') != -1">元/1000字</span>
          <span v-else >元</span>
        </template>
        <template #white = "{row}">
          <span>{{Math.round(row.price * 0.5 )}}</span>
          <span v-if="row.productName.indexOf('AI') != -1 || row.productName.indexOf('智写') != -1">元/篇</span>
          <span v-else-if="row.productName.indexOf('商标监控') != -1">元/个</span>
          <span v-else-if="row.productName.indexOf('专利翻译') != -1">元/1000字</span>
          <span v-else >元</span>
        </template>
        <template #gold = "{row}">
          <span>{{Math.round(row.price * 0.3)}}</span>
          <span v-if="row.productName.indexOf('AI') != -1 || row.productName.indexOf('智写') != -1">元/篇</span>
          <span v-else-if="row.productName.indexOf('商标监控') != -1">元/个</span>
          <span v-else-if="row.productName.indexOf('专利翻译') != -1">元/1000字</span>
          <span v-else >元</span>
        </template>
        <template #platinum = "{row}">
          <span>{{Math.round(row.price * 0.2)}}</span>
          <span v-if="row.productName.indexOf('AI') != -1 || row.productName.indexOf('智写') != -1">元/篇</span>
          <span v-else-if="row.productName.indexOf('商标监控') != -1">元/个</span>
          <span v-else-if="row.productName.indexOf('专利翻译') != -1">元/1000字</span>
          <span v-else >元</span>
        </template>
        <template #black = "{row}">
          <span>{{Math.round(row.price * 0.1)}}</span>
          <span v-if="row.productName.indexOf('AI') != -1 || row.productName.indexOf('智写') != -1">元/篇</span>
          <span v-else-if="row.productName.indexOf('商标监控') != -1">元/个</span>
          <span v-else-if="row.productName.indexOf('专利翻译') != -1">元/1000字</span>
          <span v-else >元</span>
        </template>
        <!--        数量选择-->
        <template #patentType = "{row}">
          <div v-if="fuelBagData.filter(item => item.id == row.id).length > 0" style="width: 100px;display: flex;justify-content: center;margin-left: 50px">
            <el-input-number v-model="row.number" @change="(e) => handleChange(row, e)" :min="1" size="small"  label=""></el-input-number>
          </div>
        </template>
        <template #patentAnnuityDetailList = "{row}">
          <div class="expand">
            <span>产品功能包含：</span>
            <span v-if="row.productPrice == 1899">查询次数10w次、仅限中国 、 图形+文字 、 商标公告 、 商标对比 、 商标监控 、 法律文书 、业务线索</span>
            <span v-if="row.productPrice == 135">查询次数100次、不限国家 、 图形+文字 、 商标公告 、 商标对比 </span>
            <span v-if="row.productPrice == 1255">查询次数2000次、不限国家 、 图形+文字 、 商标公告 、 商标对比 、 商标监控 、业务线索</span>
            <span v-if="row.productPrice == 5899">查询次数15000次、不限国家 、 图形+文字 、 商标公告 、 商标对比 、 商标监控 、 法律文书 、业务线索</span>
            <span v-if="row.productName.indexOf('商标监控') != -1">文字商标监控、图形商标监控、近似商标监控 、 全球43国监控、 全类别监控 、 竞争对手监控 、 报告自动发送</span>
          </div>
        </template>
        <template #price = "{row}">
          <span>{{row.price}}</span>
          <span v-if="row.productName.indexOf('AI') != -1 || row.productName.indexOf('智写') != -1">元/篇</span>
          <span v-else-if="row.productName.indexOf('商标监控') != -1">元/个</span>
          <span v-else-if="row.productName.indexOf('专利翻译') != -1">元/1000字</span>
          <span v-else >元</span>
        </template>
      </vxe-grid>
    </div>


    <!--    试用-->
<!--    <div v-if="!active" class="free">-->
<!--      <ul class="trademark_content">-->
<!--        <li  :class="['trademark_li',{'active': indexss == 5}]">-->
<!--          <div style="display: flex;justify-content: center" class="">-->
<!--            <img style="width: 20px;height: 20px;margin-top: 26px;margin-left: 2px" src="../../assets/images/services/yuan.png" alt="">-->
<!--            <span style="margin-left: 3px" class="titles">{{trialProductServiceManages[0].productName}}</span>-->
<!--          </div>-->
<!--          <span class="line_"></span>-->
<!--&lt;!&ndash;          <p class="originalPrice">原价<span>500元</span></p>&ndash;&gt;-->
<!--&lt;!&ndash;          <p class="price"><span>{{trademarkProductServiceManages[0].productPrice}}</span>/年</p>&ndash;&gt;-->
<!--          <p class="vip"><span class="line"></span> <i>试用权益</i> <span class="line"></span></p>-->
<!--          <ul class="content">-->
<!--            <li>-->
<!--              <img src="../../assets/images/services/hook.png" alt="">-->
<!--              <span>查询50次</span>-->
<!--            </li>-->
<!--            <li>-->
<!--              <img src="../../assets/images/services/hook.png" alt="">-->
<!--              <span>驳通智写5次</span>-->
<!--            </li>-->
<!--            <li>-->
<!--              <img src="../../assets/images/services/hook.png" alt="">-->
<!--              <span>7天免费使用</span>-->
<!--            </li>-->
<!--            <li>-->
<!--              <img src="../../assets/images/services/hook.png" alt="">-->
<!--              <span>图形+文字</span>-->
<!--            </li>-->
<!--            <li>-->
<!--              <img src="../../assets/images/services/hook.png" alt="">-->
<!--              <span>商标公告</span>-->
<!--            </li>-->
<!--            <li>-->
<!--              <img src="../../assets/images/services/hook.png" alt="">-->
<!--              <span>商标对比</span>-->
<!--            </li>-->
<!--            <li>-->
<!--              <img src="../../assets/images/services/hook.png" alt="">-->
<!--              <span>商标监控</span>-->
<!--            </li>-->
<!--            <li>-->
<!--              <img src="../../assets/images/services/hook.png" alt="">-->
<!--              <span>法律文书</span>-->
<!--            </li>-->
<!--            <li>-->
<!--              <img src="../../assets/images/services/hook.png" alt="">-->
<!--              <span>业务线索</span>-->
<!--            </li>-->
<!--          </ul>-->
<!--          <el-button type="primary" size="mini" :disabled="!isDisable || (isVip != 'COMMON')" class="freeBtn" @click="applicaEvent()">-->
<!--            申请试用-->
<!--          </el-button>-->
<!--&lt;!&ndash;          <div class="bottomright">&ndash;&gt;-->
<!--&lt;!&ndash;            <img src="../../assets/images/services/selected.png" alt="">&ndash;&gt;-->
<!--&lt;!&ndash;          </div>&ndash;&gt;-->
<!--        </li>-->
<!--      </ul>-->
<!--    </div>-->

    <div v-if="show && tabId == 2" class="pay">
      <div v-if="!vipInfo" class="pay_cen">
        <div class="explain">
          <img src="../../assets/images/services/heicrown.png" alt="">
          <p>加入会员，本单您将最高可省<span>{{currentPrice - discountedPrice}}</span>元</p>
<!--          <p>2、同时购买专利和商标产品，可享<span>9折</span>优惠（监控产品除外）</p>-->
        </div>
        <div  class="payPrice">
          <p>
            （黑钻）折后<span> {{discountedPrice}} 元</span>
          </p>
          <p>
            现价  <span>{{currentPrice}} 元</span>
          </p>
        </div>
        <div >
          <div @click="tabId = 1" class="toPay kai">
            开通会员
          </div>
          <div @click="toPay" class="toPay yuan">
            原价支付
          </div>
        </div>

      </div>
      <div v-if="vipInfo" class="pay_cen">
        <div class="explain">
          <img v-if="vipInfo.cardLevel == 10" src="../../assets/images/services/baicrown.png" alt="">
          <img v-if="vipInfo.cardLevel == 20" src="../../assets/images/services/huangcrown.png" alt="">
          <img v-if="vipInfo.cardLevel == 30" src="../../assets/images/services/bocrown.png" alt="">
          <img v-if="vipInfo.cardLevel == 40" src="../../assets/images/services/heicrown.png" alt="">
          <p >尊贵的{{this.getVipGrade(this.vipInfo.cardLevel)}}，本单您将节省 <span>{{currentPrice - discountedPrice}}元</span></p>
          <!--          <p>2、同时购买专利和商标产品，可享<span>9折</span>优惠（监控产品除外）</p>-->
        </div>
        <div  class="payPrice">
          <p>
            原价<span style="text-decoration: line-through"> {{currentPrice}} 元</span>
          </p>
          <p>
            折后价  <span>{{discountedPrice}} 元</span>
          </p>
        </div>
        <div >
          <div @click="toPay" class="toPay kai">
            去支付
          </div>
        </div>

      </div>
    </div>
<!--    <footers></footers>-->
    <el-dialog :visible.sync="dialogVisibles" :close-on-click-modal="false" custom-class="account_dialog"
               :before-close="closeEvent">
      <info @dialog="getDialogs" :dialogVisibles="dialogVisibles" :server-type="0" :dialog-close="dialogCloses"
            @dialogCloseBool="dialogCloseBools"></info>
    </el-dialog>
    <el-dialog :visible.sync="dialogVisible" :close-on-click-modal="false" custom-class="login_dialog"
               :before-close="closeEvent">
      <login @loginDialog="loginDialog" :dialog-close="dialogClose" @dialogCloseBool="dialogCloseBool"></login>
    </el-dialog>
  </div>
</template>

<script>
  import footers from '@/components/common/footers'
  import info from "@/components/account/info";
  import login from "@/components/common/login"
  import { mapState, mapMutations, mapGetters } from 'vuex'

  export default {
    name: 'index',
    metaInfo: {
      title: '商标查询|专利翻译|知识产权管理系统-蓝灯鱼智能检索平台', // set a title
      meta: [{                 // set meta
        name: 'keyWords',
        content: '蓝灯鱼,lanternfish,蓝灯鱼商标,蓝灯鱼翻译,蓝灯鱼专利,AI,人工智能,知识产权,蓝灯鱼招聘,蓝灯鱼地址,关于蓝灯鱼'
      }, {                 // set meta
        name: 'description',
        content: '蓝灯鱼是提供商标查询，专利检索，专利翻译服务的知识产权智能检索平台，应用人工智能技术，已发布商标图形查询，专利翻译，CPC官文提取工具，知识产权管理系统等系列产品。'
      }],
    },
    data () {
      return {
        //折后价
        discountedPrice: '',
        //现价
        currentPrice: '',
        show: false,
        fuelBagData: [],
        vipInfo: '',
        itemCopy: {},
        equityComparison: {
          tm:  [
            {
              "name": "中国大陆商标查询",
              "originalPrice": 1899,
              "white": 950,
              "gold": 570,
              "platinum": 0,
              "black": 190,
              "unit": '元'
            },
            {
              "name": "全球商标查询100次",
              "originalPrice": 135,
              "white": 68,
              "gold": 41,
              "platinum": 27,
              "black": 14,
              "unit": '元'
            },
            {
              "name": "全球商标查询2000次",
              "originalPrice": 1255,
              "white": 628,
              "gold": 377,
              "platinum": 251,
              "black": 126,
              "unit": '元'
            },
            {
              "name": "全球商标查询15000次",
              "originalPrice": 5899,
              "white": 2950,
              "gold": 1770,
              "platinum": 1180,
              "black": 0,
              "unit": '元'
            },
            {
              "name": "全球商标监控",
              "originalPrice": 10,
              "white": 5,
              "gold": 3,
              "platinum": 2,
              "black": 1,
              "unit": '元/个'
            },
            {
              "name": "驳通智写-驳回复审",
              "originalPrice": 300,
              "white": 150,
              "gold": 90,
              "platinum": 60,
              "black": 30,
              "unit": '元/篇'
            },
            {
              "name": "驳通智写-驳文转达",
              "originalPrice": 30,
              "white": 15,
              "gold": 9,
              "platinum": 6,
              "black": 3,
              "unit": '元/篇'
            },
            {
              "name": "异议智写",
              "originalPrice": 300,
              "white": 150,
              "gold": 90,
              "platinum": 60,
              "black": 30,
              "unit": '元/篇'
            }
          ],
          pt:  [
            {
              "name": "专利翻译",
              "originalPrice": "10",
              "white": "5",
              wd:'元/1000字',
              "gold": "3",
              gd: '元/1000字',
              "platinum": "2",
              pd: '元/1000字',
              "black": "1",
              bd: '元/1000字',
              "unit": '元/1000字'
            },
            {
              "name": "专利五书拆分",
              "originalPrice": 98,
              "white": 49,
              "gold": 0,
              "platinum": 0,
              "black": 0,
              "unit": '元'
            },
            {
              "name": "专利附图一键压缩",
              "originalPrice": 0,
              "white": 0,
              "gold": 0,
              "platinum": 0,
              "black": 0,
              "unit": '元'
            },
            {
              "name": "CPC官文提取（单用户）",
              "originalPrice": 998,
              "white": 499,
              "gold": 299,
              "platinum": 0,
              "black": 0,
              "unit": '元'
            },
            {
              "name": "CPC官文提取（多用户）",
              "originalPrice": 2998,
              "white": 1499,
              "gold": 899,
              "platinum": 0,
              "black": 0,
              "unit": '元'
            },
            {
              "name": "专利撰写形式核查助手",
              "originalPrice": 298,
              "white": 149,
              "gold": 89,
              "platinum": 0,
              "black": 0,
              "unit": '元'
            },
            {
              "name": "专利AI-OA精灵",
              "originalPrice": 300,
              "white": 150,
              "gold": 90,
              "platinum": 60,
              "black": 30,
              "unit": '元/篇'
            },
            {
              "name": "专利AI-芯智写",
              "originalPrice": 300,
              "white": 150,
              "gold": 90,
              "platinum": 60,
              "black": 30,
              "unit": '元/篇'
            }
          ],
          pl: [
            {
              "name": "中国大陆商标查询",
              "originalPrice": 1899,
              "white": 950,
              "gold": 570,
              "platinum": 0,
              "black": 190,
              "unit": '元'
            },
            {
              "name": "全球商标查询100次",
              "originalPrice": 135,
              "white": 68,
              "gold": 41,
              "platinum": 27,
              "black": 14,
              "unit": '元'
            },
            {
              "name": "全球商标查询2000次",
              "originalPrice": 1255,
              "white": 628,
              "gold": 377,
              "platinum": 251,
              "black": 126,
              "unit": '元'
            },
            {
              "name": "全球商标查询15000次",
              "originalPrice": 5899,
              "white": 2950,
              "gold": 1770,
              "platinum": 1180,
              "black": 0,
              "unit": '元'
            },
            {
              "name": "全球商标监控",
              "originalPrice": "10",
              "white": "5",
              wd: '元/个',
              "gold": "3",
              gd: '元/个',
              "platinum": "2",
              pd:'元/个',
              "black": "1",
              bd: '元/个',
              "unit": '元/个'
            },
            {
              "name": "驳通智写-驳回复审",
              "originalPrice": 300,
              "white": 150,
              "gold": 90,
              "platinum": 60,
              "black": 30,
              "unit": '元/篇'
            },
            {
              "name": "驳通智写-驳文转达",
              "originalPrice": 30,
              "white": 15,
              "gold": 9,
              "platinum": 6,
              "black": 3,
              "unit": '元/篇'
            },
            {
              "name": "异议智写",
              "originalPrice": 300,
              "white": 150,
              "gold": 90,
              "platinum": 60,
              "black": 30,
              "unit": '元/篇'
            },
            {
              "name": "专利翻译",
              "originalPrice": "10",
              "white": "5",
              wd: '元/1000字',
              "gold": "3",
              gd: '元/1000字',
              "platinum": "2",
              pd: '元/1000字',
              "black": "1",
              bd: '元/1000字',
              "unit": '元/1000字'
            },
            {
              "name": "专利五书拆分",
              "originalPrice": 98,
              "white": 49,
              "gold": 0,
              "platinum": 0,
              "black": 0,
              "unit": '元'
            },
            {
              "name": "专利附图一键压缩",
              "originalPrice": 0,
              "white": 0,
              "gold": 0,
              "platinum": 0,
              "black": 0,
              "unit": '元'
            },
            {
              "name": "CPC官文提取（单用户）",
              "originalPrice": 998,
              "white": 499,
              "gold": 299,
              "platinum": 200,
              "black": 50,
              "unit": '元'
            },
            {
              "name": "CPC官文提取（多用户）",
              "originalPrice": 2998,
              "white": 1499,
              "gold": 899,
              "platinum": 600,
              "black": 300,
              "unit": '元'
            },
            {
              "name": "专利撰写形式核查助手",
              "originalPrice": 298,
              "white": 149,
              "gold": 0,
              "platinum": 0,
              "black": 0,
              "unit": '元'
            },
            {
              "name": "专利AI-OA精灵",
              "originalPrice": 300,
              "white": 150,
              "gold": 90,
              "platinum": 60,
              "black": 30,
              "unit": '元/篇'
            },
            {
              "name": "专利AI-芯智写",
              "originalPrice": 300,
              "white": 150,
              "gold": 90,
              "platinum": 60,
              "black": 30,
              "unit": '元/篇'
            }
          ]
        },
        gridOptions: {
          resizable: true,
          showOverflow: true,
          columns: [
            { field: 'name', title: '服务名称' },
            { field: 'originalPrice', title: '原价', showHeaderOverflow: true, slots: {
                default: 'originalPrice'
              } },
            { field: 'white', title: '白银会员', showOverflow: true, slots: {
                default: 'white'
              } },
            { field: 'gold', title: '黄金会员', showOverflow: true, slots: {
                default: 'gold'
              } },
            { field: 'platinum', title: '铂金会员', showOverflow: true, slots: {
                default: 'platinum'
              } },
            { field: 'black', title: '黑钻会员', showOverflow: true, slots: {
                default: 'black'
              } }
          ],
          data: []
        },
        gridOptionss:{
          id: 'annualFeeGrid',
          resizable: true,
          showHeaderOverflow: true,
          showOverflow: true,
          highlightHoverRow: true,
          keepSource: true,
          height: 600,
          rowId: 'id',
          expandConfig: {
            accordion: true,
            lazy: false,
            reserve: false
          },
          filterConfig: {
            remote: true,
            iconNone: 'el-icon-search',
          },
          sortConfig: {
            trigger: 'cell',
            remote: true
          },
          columnKey: true,
          scrollX: {
            enabled: false
          },
          data: [],
          Props: {
            stripe: true,
          },
          columns:[
            {
              type: 'checkbox',
              width: '60',
            },
            {
              align: 'left',
              field: 'productName',
              title: '服务名称',
              slots: {
                default: 'productName'
              }
            },
            {
              align: 'center',
              field: 'productPrice',
              title: '原价',
              width: 170,
              slots: {
                default: 'productPrice'
              }
            },
            {
              align: 'left',
              field: 'white',
              title: '白银会员',
              width: 130,
              vip: false,
              chang: true,
              visible: true,
              slots: {
                default: 'white'
              }
            },
            {
              align: 'left',
              field: 'gold',
              title: '黄金会员',
              width: 130,
              vip: false,
              chang: true,
              visible: true,
              slots: {
                default: 'gold'
              }
            },
            {
              align: 'left',
              field: 'platinum',
              title: '铂金会员',
              width: 130,
              vip: false,
              chang: true,
              visible: true,
              slots: {
                default: 'platinum'
              }
            },
            {
              align: 'left',
              field: 'black',
              title: '黑钻会员',
              width: 130,
              vip: false,
              chang: true,
              visible: true,
              slots: {
                default: 'black'
              }
            },
            {
              align: 'left',
              field: 'price',
              title: '会员价',
              vip: true,
              visible: false,
              chang: true,
              slots: {
                default: 'price'
              }
            },
            {
              align: 'left',
              field: 'patentType',
              title: '',
              slots: {
                default: 'patentType'
              }
            },
            {
              type: 'expand',
              visible: false,
              slots: {
                content:'patentAnnuityDetailList'
              }
            }
          ],
          checkboxConfig: {
            reserve: true,
            highlight: true,
            range: true
          },
        },
        tabList: [
          {
            name: 'VIP会员',
            id: 1
          },
          {
            name: '加油包',
            id: 2
          }
        ],
        tabId: 1,
        flag: true,
        isDisable: true,
        num: 10,
        nums: 10,
        saveOrderId: "",
        saveProductServiceId: "",
        saveOrderCurrency: "",
        //是否申请过
        existApply:false,
        dialogClose: false,
        dialogCloses: false,
        dialogVisibles: false,
        dialogVisible: false,
        price:'',
        prices:"",
        active: true,
        isCN: true,
        indexs: -1,
        indexss: 0,
        indexsss: 0,
        indexssss: 0,
        trademarkProductServiceManages: [
          {
            productName:'',
            productPrice:''
          },
          {
            productName:'',
            productPrice:''
          },
          {
            productName:'',
            productPrice:''
          },
          {
            productName:'',
            productPrice:''
          }
        ],
        patentProductServiceManages: [],
        custodyProductServiceManages:[],
        trialProductServiceManages: [],
        extendProductServiceManages:[],
        //加油包产品列表
        fuelPublishServiceList: [],
        isVip: "COMMON"
      }
    },
    computed: {
      ...mapState(["me", "noticeList", "headerBgBool"]),
    },
    async mounted() {
      if(this.$i18n.locale === 'cn'){
        this.isCN = true
      }else {
        this.isCN = false
      }
      this.gridOptions.data = this.equityComparison.tm
      await this.getVipInfo()
      await this.getList()
      await this.freeTimes()
      await this.isVips()
      await this.getFuelPublishServiceList()
      await this.getCustodyPublishServiceList()
    },
    components: {
      footers,
      info,
      login
    },
    methods: {
      getVipType(item) {
        switch (item) {
          case 'vip_pt': return '专利'; break;
          case 'vip_tm': return '商标'; break;
          case 'vip_plus': return '联合'; break;
        }
      },
      getVipGrade(item) {
        switch (item) {
          case '10': return '白银会员'; break;
          case '20': return '黄金会员'; break;
          case '30': return '铂金会员'; break;
          case '40': return '黑金会员'; break;
        }
      },
      getVipInfo(){
        this.$axios
          .get("/admin/member/user/get").then(({data}) => {
          if(data.code == 0){
            if(data.data) {
              this.vipInfo = data.data.card
              this.gridOptionss.columns = this.gridOptionss.columns.map(item => {
                if(item.chang) {
                  if(item.vip) {
                    item.visible = true
                  }else  {
                    item.visible = false
                  }
                }
                if(item.field == 'price') {
                  item.title = '会员价（您当前为商标'+ this.getVipGrade(this.vipInfo.cardLevel) +'）'
                }
                item.arrow = true
                return item
              })
            }else {
              this.gridOptionss.columns = this.gridOptionss.columns.map(item => {
                if(item.chang) {
                  if(item.vip) {
                    item.visible = false
                  }else  {
                    item.visible = true
                  }
                }
                item.arrow = true
                return item
              })
            }
            console.log(this.vipInfo,"this.vipInfo")
          }
        })
      },
      tabVip(val) {
        this.active = val
        if(val == 1) {
          this.gridOptions.data = this.equityComparison.tm
        }else if(val == 2) {
          this.gridOptions.data = this.equityComparison.pt
        }else if(val == 3) {
          this.gridOptions.data = this.equityComparison.pl
        }
      },
      getFuelPublishServiceList(){
        this.$axios
          .get("/product/productservicemanage/getFuelPublishServiceList").then(({data}) => {
            console.log(data,"datadata===")
          if(data.code == 0){
            this.gridOptionss.data = data.data.map(item => {
              if(item.serviceName.indexOf('专利') != -1 || item.serviceName.indexOf('CPC') != -1) {
                if(this.vipInfo.cardType == 'vip_pt'){
                  item.price = Math.round(item.productPrice * (this.vipInfo.discountPercent/100))
                }
                item.type = 'pt'
              }else {
                if(this.vipInfo.cardType == 'vip_tm'){
                  item.price = Math.round(item.productPrice * (this.vipInfo.discountPercent/100))
                }
                item.type = 'tm'
              }
              if(this.vipInfo.cardType == 'vip_plus'){
                item.price = Math.round(item.productPrice * (this.vipInfo.discountPercent/100))
              }
              item.number = 1
              return item;
            })
          }
        })
      },
      getCustodyPublishServiceList(){
        this.$axios
          .get("/product/productservicemanage/getCustodyPublishServiceList").then(({data}) => {
          if(data.code == 0){
            this.custodyProductServiceManages = data.data.sort(function (a, b) {
              return (a.cardLevel - b.cardLevel);
            })
          }
        })
      },
      orderCreate(item){
        this.$axios
          .post("/order/member/order/create",{
            cardId: item.id,
            currency: 'CNY', //币种：CNY人民币,USD美元,EUR欧元,JPY日元
            source: "web",
            remark: item.cardName
          }).then(({data}) => {
          if(data.code == 0){
            if(data.code == 0) {
              this.$router.push({
                path:'/pay/orderInfo',
                query:{
                  vip: '1',
                  id: data.data.id,
                  payOrderId: data.data.payOrderId,
                  modeType: '',
                  name: '',
                  discountFlag: 0,
                }
              })
            }
          }
        })
      },
      handleChange(item, value) {
        item.number = value
        this.calculateFunction()
      },
      //设定表格cell属性
      cellClassName({row, column, $columnIndex}) {
        if($columnIndex == 0){
          return 'color-row1-1';
        }
      },
      //设定表格cell属性
      cellClassNames({row, column, $columnIndex}) {
        // if($columnIndex == 0 || $columnIndex == 1){
        //   return 'color-row1-1';
        // }

        if($columnIndex == 1){
          return 'color-row1-1 color-row1-s';
        }


        if($columnIndex == 0){
          return 'color-row1-1 cell';
        }
      },
      tableRowClassNames({row, rowIndex}) {
        // if(row.patentLawStatus != 1){
        //   return 'color-row1-1';
        // }
      },
      //设置表格行属性
      rowStyle({row, rowIndex, $rowIndex}) {
        //本月应该缴纳的年费数据修改样式
        // if(
        //   moment(row.annualFeeDate).subtract(1, "months").isBefore(moment.now())
        // ){
        //   return 'color:red;'
        // }
      },
      cellClick({
                  row,
                  rowIndex,
                  $rowIndex,
                  column,
                  columnIndex,
                  $columnIndex,
                  triggerRadio,
                  triggerCheckbox,
                  triggerTreeNode,
                  triggerExpandNode,
                  $event
                }){
        if((row.productName.indexOf('查询') != -1 || row.productName.indexOf('商标监控') != -1) && $columnIndex == 1) {
          this.gridOptionss.data = this.gridOptionss.data.map(item => {
            if(item.productName != row.productName) {
              item.arrow = false
            }
            return item
          })
          row.arrow = !row.arrow
          this.$refs.fuelBag.toggleRowExpand(row)
        }
        this.calculateFunction()
      },
      isChecked({records}){
        if(records.length > 0) {
          this.show = true
        }else {
          this.show = false
        }
        this.calculateFunction()
        this.fuelBagData = this.$refs.fuelBag.getCheckboxRecords()
      },
      isCheckeds({checked}){
        if(checked) {
          this.show = true
        }else {
          this.show = false
        }
        this.calculateFunction()
        this.fuelBagData = this.$refs.fuelBag.getCheckboxRecords()
      },
      tabEvent(item){
        this.tabId = item.id
      },
      getVipType(){
        let obj = null
        switch (this.me.vipType) {
          case "VIP_A": obj = {
            num: 1,
            text: '白银卡'
          };break;
          case "VIP_B": obj = {
            num: 2,
            text: '黄金卡'
          };break;
          case "VIP_C": obj = {
            num: 3,
            text: '铂金卡'
          };break;
          case "VIP_D": obj = {
            num: 4,
            text: '黑钻卡'
          };break;
        }
          return obj;
      },
      //是否商标vip
      isVips(){
        this.$axios
          .get("/product/servicemanage/getTmVipLevel").then(({data}) => {
          if(data.code == 0){
            this.isVip = data.data
          }
        })
      },
      //试用服务次数查询
      freeTimes(){
        this.$axios
          .get("/product/servicemanage/getTrialCount").then(({data}) => {
          if(data.code == 0){
            this.isDisable = data.data.totalTrialCount - data.data.trialCount
          }
        })
      },
      // 生成订单
      async saveOrderAjax () {
        let data = await this.$axios.post("/order/order/saveLanternfishOrder", {
          productServiceId: this.saveProductServiceId,
          productId: this.saveOrderId,
          currency: this.saveOrderCurrency, //币种：CNY人民币,USD美元,EUR欧元,JPY日元
          source: "web",
          orderType: "lantern" //订单类型： 蓝灯鱼订单为 lantern  普通订单为common
        });
        if (data.data.code == 0) {
          this.$router.push({
            path: "/pay/paySuccess",
            query: {
              payType: 2,
              td:  -11  ,
              try: 'try'
            }
          });
        }
      },
      getDialogs (param) {
        this.dialogVisibles = param;
        this.saveOrderAjax();
      },
      // 申请
      applicaEvent () {
        if (this.tool.getCookie("auth")) {
          let param = this.trialProductServiceManages[0]
          this.saveOrderId = param.productId;
          this.saveProductServiceId = param.id;
          this.saveOrderCurrency = param.currency;
          //是否申请过
          this.existApply = param.existApply
          //自动开启服务的标识符
          // this.translateId = param.translateId
          //   let data = await this.$axios.get(`/order/order/existLanternfishApplyOrder/${param.id}`);
          //   if (data.data.code == 0) {
          //     if (data.data.data) {
          //       param.alreadyAppliedBool = true;
          //     } else {
          //       param.alreadyAppliedBool = false;
          this.saveOrderAjax();
        } else {
          this.dialogVisible = true
        }
      },
      // 登录弹层隐藏
      loginDialog(data) {
        this.dialogVisible = data;
        // this.init();
        //   if (!this.unLoginBool  && this.serviceNameType == 2) {
        //     this.$router.push({
        //       path: "/account/trialInfo",
        //       query: {
        //         serverType: 2
        //       }
        //     })
        //   }
      },
      closeEvent(done) {
        this.dialogClose = true;
        done();
      },
      dialogCloseBool(data) {
        this.dialogClose = data;
      },
      closeEvents(done) {
        this.dialogCloses = true;
        done();
      },
      dialogCloseBools(data) {
        this.dialogCloses = data;
      },
      //去支付
      toPay(item,indexs){
        if (this.tool.getCookie("auth")) {
        } else {
          this.dialogVisible = true
          return;
        }
        //
        // let data = {}
        // if(this.indexs && !this.indexss){
        //   data = this.trademarkProductServiceManages[this.indexs - 1]
        //   this.prices = this.trademarkProductServiceManages[this.indexs - 1].price
        //   if(this.indexsss){
        //     this.prices += this.extendProductServiceManages[this.indexsss - 1].price*(this.num/10)
        //   }
        // }else if(!this.indexs && this.indexss){
        //   data = this.patentProductServiceManages[this.indexss - 1]
        //   this.prices = this.patentProductServiceManages[this.indexss - 1].price
        //   if(this.indexsss){
        //     this.prices += this.extendProductServiceManages[this.indexsss - 1].price*(this.num/10)
        //   }
        // }else if(this.indexs && this.indexss){
        //   // data = this.patentProductServiceManages[this.indexss - 1]
        //   this.price = this.trademarkProductServiceManages[this.indexs - 1].price + this.patentProductServiceManages[this.indexss - 1].price
        //   this.prices = this.price*0.9
        //   if(this.indexsss){
        //     this.prices += this.extendProductServiceManages[this.indexsss - 1].price*(this.num/10)
        //   }
        // }
        if(this.tabId == 1 && indexs < 12) {
          this.orderCreate(item)
        }else if(this.tabId == 1 && indexs >= 12) {
          this.$router.push({
            path:'/pay/orderInfo',
            query:{
              id: item.id,
              modeType: '',
              tariff: item.price,
              name: '',
              discountFlag: 0,
              flag: this.$route.query.flag && this.flag ? this.$route.query.flag : undefined
            }
          })
        }else if(this.tabId == 2) {
          let data = this.$refs.fuelBag.getCheckboxRecords()
          localStorage.setItem('vip', JSON.stringify(data))
          this.$router.push({
            path:'/pay/orderInfo',
            query:{
              vip: '2',
              id: data.map(item => item.id).join(';'),
              modeType: '',
              tariff: this.vipInfo ? this.discountedPrice : this.currentPrice,
              name: '',
              discountFlag: 0,
              monitorFlag: this.indexsss == 1 ? this.num : this.indexsss == 2 ? this.nums : undefined,
              flag: this.$route.query.flag && this.flag ? this.$route.query.flag : undefined
            }
          })
        }else {
          this.$router.push({
            path:'/pay/orderInfo',
            query:{
              id: [this.extendProductServiceManages[this.indexsss - 1] ? this.extendProductServiceManages[this.indexsss - 1].id : '', this.trademarkProductServiceManages[this.indexs - 1] ? this.trademarkProductServiceManages[this.indexs - 1].id : '' , this.patentProductServiceManages[this.indexss - 1] ? this.patentProductServiceManages[this.indexss - 1].id : '', this.custodyProductServiceManages[this.indexssss - 1] ? this.custodyProductServiceManages[this.indexssss - 1].id : ''].filter(item => item).join(';'),
              modeType: '',
              tariff: this.prices,
              name: '',
              discountFlag: 0,
              monitorFlag: this.indexsss == 1 ? this.num : this.indexsss == 2 ? this.nums : undefined,
              flag: this.$route.query.flag && this.flag ? this.$route.query.flag : undefined
            }
          })
        }
      },
      //获取订购购买服务列表接口
      getList(){

        this.$axios.get('/admin/member/card/list').then(({data}) => {
          if(data.code == 0){
            this.trademarkProductServiceManages = data.data.filter(item => item.cardType == 'vip_tm').map(item => {
              item.productPrice = item.price/100
              return item
            }).sort(function (a, b) {
              return (a.cardLevel - b.cardLevel);
            })
            this.patentProductServiceManages = data.data.filter(item => item.cardType == 'vip_pt').map(item => {
              item.productPrice = item.price/100
              return item
            }).sort(function (a, b) {
              return (a.cardLevel - b.cardLevel);
            })
            this.trialProductServiceManages = data.data.filter(item => item.cardType == 'vip_plus').map(item => {
              item.productPrice = item.price/100
              return item
            }).sort(function (a, b) {
              return (a.cardLevel - b.cardLevel);
            })

            // if(this.$route.query.flag){
            //   this.num = this.$route.query.value
            //   this.tabss(1)
            //   this.$router.push({
            //     path:'/pay/orderInfo',
            //     query:{
            //       id: this.$route.query.prodServiceId,
            //       modeType: '',
            //       tariff: this.$route.query.prices1,
            //       name: '',
            //       discountFlag: 0,
            //       monitorFlag: this.indexsss  ? this.num : undefined,
            //       flag: this.$route.query.flag && this.flag ? this.$route.query.flag : undefined
            //     }
            //   })
            // }
          }
        }).catch(err => {

        })
      },
      jump(){
        this.$router.push({
          path:'/recruitment'
        })
      },
      //
      jisuan(){
        let data = ''
        if(this.indexs && !this.indexss){
          data = this.trademarkProductServiceManages[this.indexs - 1]
          this.prices = this.trademarkProductServiceManages[this.indexs - 1].price
          if(this.indexsss == 1){
            this.prices += this.extendProductServiceManages[this.indexsss - 1].price*(this.num/10)
          }
          if(this.indexsss == 2){
            this.prices += this.extendProductServiceManages[this.indexsss - 1].price*(this.nums/10)
          }
          if(this.indexssss){
            //custodyProductServiceManages
            this.prices += this.custodyProductServiceManages[this.indexssss - 1].price
          }
        }else if(!this.indexs && this.indexss){
          data = this.patentProductServiceManages[this.indexss - 1]
          this.prices = this.patentProductServiceManages[this.indexss - 1].price
          if(this.indexsss == 1){
            this.prices += this.extendProductServiceManages[this.indexsss - 1].price*(this.num/10)
          }
          if(this.indexsss == 2){
            this.prices += this.extendProductServiceManages[this.indexsss - 1].price*(this.nums/10)
          }
          if(this.indexssss){
            //custodyProductServiceManages
            this.prices += this.custodyProductServiceManages[this.indexssss - 1].price
          }
        }else if(this.indexs && this.indexss){
          // data = this.patentProductServiceManages[this.indexss - 1]
          this.price = Number(this.trademarkProductServiceManages[this.indexs - 1].price) + Number(this.patentProductServiceManages[this.indexss - 1].price)
          this.prices = this.price*0.9
          if(this.indexsss == 1){
            this.prices += this.extendProductServiceManages[this.indexsss - 1].price*(this.num/10)
          }
          if(this.indexsss == 2){
            this.prices += this.extendProductServiceManages[this.indexsss - 1].price*(this.nums/10)
          }
          if(this.indexssss){
            //custodyProductServiceManages
            this.prices += this.custodyProductServiceManages[this.indexssss - 1].price
          }
        }else if(!this.indexs && !this.indexss && !this.indexssss && this.indexsss){
          if(this.indexsss == 1){
            this.prices = this.extendProductServiceManages[this.indexsss - 1].price*(this.num/10)
          }
          if(this.indexsss == 2){
            this.prices = this.extendProductServiceManages[this.indexsss - 1].price*(this.nums/10)
          }
        }else if(!this.indexs && !this.indexss && this.indexssss){
          this.prices = this.custodyProductServiceManages[this.indexssss - 1].price
          if(this.indexsss == 1){
            this.prices += this.extendProductServiceManages[this.indexsss - 1].price*(this.num/10)
          }
          if(this.indexsss == 2){
            this.prices += this.extendProductServiceManages[this.indexsss - 1].price*(this.nums/10)
          }
        }
      },
      tab(item,index){
        this.indexs = index
        this.itemCopy = item
        // if(this.indexs == index){
        //   this.indexs = 0
        //   this.jisuan()
        //   return;
        // }
        // this.indexs = index
        // this.jisuan()
      },
      tabs(index){
        if(this.indexss == index){
          this.indexss = 0
          this.jisuan()
          return;
        }
        this.indexss = index
        this.jisuan()
      },
      tabss(index){
        if(this.indexsss == index){
          this.flag = false
          this.indexsss = 0
          this.jisuan()
          return;
        }
        this.indexsss = index
        this.flag = true
        this.jisuan()
      },
      tabsss(index){
        if(this.indexssss == index){
          this.flag = false
          this.indexssss = 0
          this.jisuan()
          return;
        }
        this.indexssss = index
        this.flag = true
        this.jisuan()
      },
      calculateFunction() {
        this.discountedPrice = 0
        this.currentPrice = 0
        let data = this.$refs.fuelBag.getCheckboxRecords()
        if(data.length > 0) {
          if(this.vipInfo) {
            data.map(item => {
              this.discountedPrice += item.price*item.number
            })
            data.map(item => {
              this.currentPrice += item.productPrice*item.number
            })
          }else {
            data.map(item => {
              this.discountedPrice += Math.round(item.productPrice * 0.1)*item.number
            })
            data.map(item => {
              this.currentPrice += item.productPrice*item.number
            })
          }
        }else {
          this.discountedPrice = 0
          this.currentPrice = 0
        }
      }
    },
    watch:{
      tabId(){
        window.scrollTo({
          top: 0,         // 滚动到顶部
          behavior: 'smooth' // 平滑滚动效果，可选 'auto' 或 'smooth'
        });
      },
      indexssss(newVal){
        if(this.getVipType() && newVal != 0){
          if(newVal < this.getVipType().num){
            this.indexssss = 0
            this.$message({
              showClose: true,
              message: `您现在是${this.getVipType().text}，购买会员等级不得低于当前会员等级`,
              type: 'error'
            });
          }
        }
      },
      num(newValue,oldValue){
        this.jisuan()
      },
      nums(newValue,oldValue){
        this.jisuan()
      }
    }
  }
</script>

<style scoped lang="less">
  .index_wrap {
    background: #F3F5F9;
    display: flex;
    flex-direction: column;
    align-items: center;
    .title{
      display: flex;
      //justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 100%;
      height: 513px;
      position: relative;
      background: url("../../assets/images/services/banner.png") no-repeat;
      background-size: 100% 100%;
      .title_content {
        margin-top: 60px;
        margin-bottom: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        font-size: 45px;
        color: #333333;
        letter-spacing: 3px;
        span {
          margin: 0 14px;
        }
        img {
          margin-top: -8px;
          width: 55px;
          height: auto;
        }
      }
      .title_sub {
        font-weight: 400;
        font-size: 20px;
        color: #666666;
      }
      .tabBtn {
        margin-top: 45px;
        display: flex;
        width: 298px;
        height: 60px;
        background: #FFFFFF;
        box-shadow: 0px 1px 8px 1px rgba(0,0,0,0.12);
        border-radius: 35px 35px 35px 35px;
        span {
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: bold;
          font-size: 20px;
          color: #333333;
          width: 149px;
          height: 60px;
          border-radius: 0px 0px 0px 0px;
          &.active {
            background: #0061E3;
            border-radius: 35px 35px 35px 35px;
            font-weight: bold;
            font-size: 20px;
            color: #FFFFFF;
          }
        }
      }
    }
    .tabName {
      display: flex;
      li {
        cursor: pointer;
        &.active {
          color: red;
        }
      }
    }
    .vipBox {
      position: relative;
      top: -95px;
      width: 1200px;
      height: 784px;
      &.active1 {
        background: url("../../assets/images/services/bg1.png") no-repeat;
        .tab{
          width: 1000px;
          height: 56px;
          border-radius: 8px;
          display: flex;
          margin: 35px 60px 60px 80px;
          align-items: center;
          justify-content: space-between;
          padding: 0 6px;
          box-sizing: border-box;
          span:nth-of-type(1){
            cursor: pointer;
            width: 110px;
            height: 46px;
            line-height: 46px;
            text-align: center;
            display: block;
            border-radius: 8px;
            font-weight: 500;
            font-size: 22px;
            color: #555555;
            position: relative;
            &::before {
              position: absolute;
              content: '';
              width: 2px;
              height: 22px;
              border-radius: 0px 0px 0px 0px;
              background: #6195DB;
              right: -95px;
              top: 12px;
            }
            &.active{
              font-weight: bold;
              font-size: 24px;
              color: #333333;
              margin-top: -25px;
              &:before {
                position: absolute;
                content: url("../../assets/images/services/Ellipse.png") ;
                width: 24px;
                height: auto;
                background: transparent;
                border-radius: 0px 0px 0px 0px;
                right: 44px;
                top: 26px;
              }
            }
          }
          span:nth-of-type(2){
            cursor: pointer;
            width: 124px;
            height: 46px;
            line-height: 46px;
            text-align: center;
            display: block;
            border-radius: 8px;
            font-weight: 500;
            font-size: 22px;
            color: #555555;
            position: relative;
            &::before {
              position: absolute;
              content: '';
              width: 2px;
              height: 22px;
              border-radius: 0px 0px 0px 0px;
              background: #6195DB;
              right: -60px;
              top: 12px;
            }
            &.active{
              font-weight: bold;
              font-size: 24px;
              color: #333333;
              margin-top: -25px;
              &:before {
                position: absolute;
                content: url("../../assets/images/services/Ellipse.png") ;
                width: 24px;
                height: auto;
                background: transparent;
                border-radius: 0px 0px 0px 0px;
                right: 44px;
                top: 26px;
              }
            }
          }
          span:nth-of-type(3){
            cursor: pointer;
            width: 124px;
            height: 46px;
            line-height: 46px;
            text-align: center;
            display: block;
            border-radius: 8px;
            font-weight: 500;
            font-size: 22px;
            color: #555555;
            position: relative;
            &::before {
              position: absolute;
              content: '';
              width: 2px;
              height: 22px;
              border-radius: 0px 0px 0px 0px;
              background: #6195DB;
              right: -60px;
              top: 12px;
            }
            &.active{
              font-weight: bold;
              font-size: 24px;
              color: #333333;
              margin-top: -25px;
              &:before {
                position: absolute;
                content: url("../../assets/images/services/Ellipse.png") ;
                width: 24px;
                height: auto;
                background: transparent;
                border-radius: 0px 0px 0px 0px;
                right: 44px;
                top: 26px;
              }
            }
          }

          span:nth-of-type(4){
            cursor: pointer;
            width: 124px;
            height: 46px;
            line-height: 46px;
            text-align: center;
            display: block;
            border-radius: 8px;
            font-weight: 500;
            font-size: 22px;
            color: #555555;
            position: relative;
            //&::before {
            //  content: '';
            //  width: 2px;
            //  height: 22px;
            //  border-radius: 0px 0px 0px 0px;
            //  background: #6195DB;
            //  right: -100px;
            //  top: 12px;
            //}
            &.active{
              font-weight: bold;
              font-size: 24px;
              color: #333333;
              margin-top: -25px;
              &:before {
                position: absolute;
                content: url("../../assets/images/services/Ellipse.png") ;
                width: 24px;
                height: auto;
                background: transparent;
                border-radius: 0px 0px 0px 0px;
                right: 44px;
                top: 26px;
              }
            }
          }
        }
      }
      &.active2 {
        height: 783px;
        background: url("../../assets/images/services/bg2.png") no-repeat;
        .trademark .trademark_content .trademark_li {
          height: 556px;
        }
        .tab{
          width: 1000px;
          height: 56px;
          border-radius: 8px;
          display: flex;
          margin: 35px 60px 60px 70px;
          align-items: center;
          justify-content: space-between;
          padding: 0 6px;
          box-sizing: border-box;
          span:nth-of-type(1){
            cursor: pointer;
            width: 110px;
            height: 46px;
            line-height: 46px;
            text-align: center;
            display: block;
            border-radius: 8px;
            font-weight: 500;
            font-size: 22px;
            color: #555555;
            position: relative;
          }
          span:nth-of-type(2){
            cursor: pointer;
            width: 124px;
            height: 46px;
            line-height: 46px;
            text-align: center;
            display: block;
            border-radius: 8px;
            font-weight: 500;
            font-size: 22px;
            color: #555555;
            position: relative;
            &::before {
              position: absolute;
              content: '';
              width: 2px;
              height: 22px;
              border-radius: 0px 0px 0px 0px;
              background: #6195DB;
              right: -60px;
              top: 12px;
            }
            &.active{
              font-weight: bold;
              font-size: 24px;
              color: #333333;
              margin-top: -25px;
              &:before {
                position: absolute;
                content: url("../../assets/images/services/Ellipse.png") ;
                width: 24px;
                height: auto;
                background: transparent;
                border-radius: 0px 0px 0px 0px;
                right: 53px;
                top: 26px;
              }
            }
          }
          span:nth-of-type(3){
            cursor: pointer;
            width: 124px;
            height: 46px;
            line-height: 46px;
            text-align: center;
            display: block;
            border-radius: 8px;
            font-weight: 500;
            font-size: 22px;
            color: #555555;
            position: relative;
            &::before {
              position: absolute;
              content: '';
              width: 2px;
              height: 22px;
              border-radius: 0px 0px 0px 0px;
              background: #6195DB;
              right: -80px;
              top: 12px;
            }
            &.active{
              font-weight: bold;
              font-size: 24px;
              color: #333333;
              margin-top: -25px;
              &:before {
                position: absolute;
                content: url("../../assets/images/services/Ellipse.png") ;
                width: 24px;
                height: auto;
                background: transparent;
                border-radius: 0px 0px 0px 0px;
                right: 44px;
                top: 26px;
              }
            }
          }
          span:nth-of-type(4){
            cursor: pointer;
            width: 124px;
            height: 46px;
            line-height: 46px;
            text-align: center;
            display: block;
            border-radius: 8px;
            font-weight: 500;
            font-size: 22px;
            color: #555555;
            position: relative;
            //&::before {
            //  content: '';
            //  width: 2px;
            //  height: 22px;
            //  border-radius: 0px 0px 0px 0px;
            //  background: #6195DB;
            //  right: -100px;
            //  top: 12px;
            //}
            &.active{
              font-weight: bold;
              font-size: 24px;
              color: #333333;
              margin-top: -25px;
              &:before {
                position: absolute;
                content: url("../../assets/images/services/Ellipse.png") ;
                width: 24px;
                height: auto;
                background: transparent;
                border-radius: 0px 0px 0px 0px;
                right: 44px;
                top: 26px;
              }
            }
          }
        }
      }
      &.active3 {
        height: 1041px;
        .trademark .trademark_content .trademark_li {
          height: 842px;
        }
        background: url("../../assets/images/services/bg3.png") no-repeat;
        .tab{
          width: 1000px;
          height: 56px;
          border-radius: 8px;
          display: flex;
          margin: 35px 60px 60px 60px;
          align-items: center;
          justify-content: space-between;
          padding: 0 6px;
          box-sizing: border-box;
          span:nth-of-type(1){
            cursor: pointer;
            width: 110px;
            height: 46px;
            line-height: 46px;
            text-align: center;
            display: block;
            border-radius: 8px;
            font-weight: 500;
            font-size: 22px;
            color: #555555;
            position: relative;
            &::before {
              position: absolute;
              content: '';
              width: 2px;
              height: 22px;
              border-radius: 0px 0px 0px 0px;
              background: #6195DB;
              right: -95px;
              top: 12px;
            }
            &.active{
              font-weight: bold;
              font-size: 24px;
              color: #333333;
              margin-top: -25px;
              margin-left: -10px;
              &:before {
                position: absolute;
                content: url("../../assets/images/services/Ellipse.png") ;
                width: 24px;
                height: auto;
                background: transparent;
                border-radius: 0px 0px 0px 0px;
                right: 44px;
                top: 26px;
              }
            }
          }
          span:nth-of-type(2){
            cursor: pointer;
            width: 124px;
            height: 46px;
            line-height: 46px;
            text-align: center;
            display: block;
            border-radius: 8px;
            font-weight: 500;
            font-size: 22px;
            color: #555555;
            position: relative;
          }
          span:nth-of-type(3){
            cursor: pointer;
            width: 124px;
            height: 46px;
            line-height: 46px;
            text-align: center;
            display: block;
            border-radius: 8px;
            font-weight: 500;
            font-size: 22px;
            color: #555555;
            position: relative;
            &::before {
              position: absolute;
              content: '';
              width: 2px;
              height: 22px;
              border-radius: 0px 0px 0px 0px;
              background: #6195DB;
              right: -60px;
              top: 12px;
            }
            &.active{
              font-weight: bold;
              font-size: 24px;
              color: #333333;
              margin-top: -25px;
              margin-left: -10px;
              &:before {
                position: absolute;
                content: url("../../assets/images/services/Ellipse.png") ;
                width: 24px;
                height: auto;
                background: transparent;
                border-radius: 0px 0px 0px 0px;
                right: 53px;
                top: 26px;
              }
            }
          }

          span:nth-of-type(4){
            cursor: pointer;
            width: 124px;
            height: 46px;
            line-height: 46px;
            text-align: center;
            display: block;
            border-radius: 8px;
            font-weight: 500;
            font-size: 22px;
            color: #555555;
            position: relative;
            //&::before {
            //  content: '';
            //  width: 2px;
            //  height: 22px;
            //  border-radius: 0px 0px 0px 0px;
            //  background: #6195DB;
            //  right: -100px;
            //  top: 12px;
            //}
            &.active{
              font-weight: bold;
              font-size: 24px;
              color: #333333;
              margin-top: -25px;
              margin-left: -10px;
              &:before {
                position: absolute;
                content: url("../../assets/images/services/Ellipse.png") ;
                width: 24px;
                height: auto;
                background: transparent;
                border-radius: 0px 0px 0px 0px;
                right: 44px;
                top: 26px;
              }
            }
          }
        }
      }
      &.active4 {
        height: 630px;
        .trademark .trademark_content .trademark_li {
          height: 416px;
        }
        background: url("../../assets/images/services/bg4.png") no-repeat;
        .tab{
          width: 1030px;
          height: 56px;
          border-radius: 8px;
          display: flex;
          margin: 35px 60px 60px 105px;
          align-items: center;
          justify-content: space-between;
          padding: 0 6px;
          box-sizing: border-box;
          span:nth-of-type(1){
            cursor: pointer;
            width: 110px;
            height: 46px;
            line-height: 46px;
            text-align: center;
            display: block;
            border-radius: 8px;
            font-weight: 500;
            font-size: 22px;
            color: #555555;
            position: relative;
            &::before {
              position: absolute;
              content: '';
              width: 2px;
              height: 22px;
              border-radius: 0px 0px 0px 0px;
              background: #6195DB;
              right: -90px;
              top: 12px;
            }
            &.active{
              font-weight: bold;
              font-size: 24px;
              color: #333333;
              margin-top: -25px;
              &:before {
                position: absolute;
                content: url("../../assets/images/services/Ellipse.png") ;
                width: 24px;
                height: auto;
                background: transparent;
                border-radius: 0px 0px 0px 0px;
                right: 44px;
                top: 26px;
              }
            }
          }
          span:nth-of-type(2){
            cursor: pointer;
            width: 124px;
            height: 46px;
            line-height: 46px;
            text-align: center;
            display: block;
            border-radius: 8px;
            font-weight: 500;
            font-size: 22px;
            color: #555555;
            position: relative;
            &::before {
              position: absolute;
              content: '';
              width: 2px;
              height: 22px;
              border-radius: 0px 0px 0px 0px;
              background: #6195DB;
              right: -75px;
              top: 12px;
            }
            &.active{
              font-weight: bold;
              font-size: 24px;
              color: #333333;
              margin-top: -25px;
              &:before {
                position: absolute;
                content: url("../../assets/images/services/Ellipse.png") ;
                width: 24px;
                height: auto;
                background: transparent;
                border-radius: 0px 0px 0px 0px;
                right: 44px;
                top: 26px;
              }
            }
          }
          span:nth-of-type(3){
            cursor: pointer;
            width: 124px;
            height: 46px;
            line-height: 46px;
            text-align: center;
            display: block;
            border-radius: 8px;
            font-weight: 500;
            font-size: 22px;
            color: #555555;
            position: relative;
          }

          span:nth-of-type(4){
            cursor: pointer;
            width: 124px;
            height: 46px;
            line-height: 46px;
            text-align: center;
            display: block;
            border-radius: 8px;
            font-weight: 500;
            font-size: 22px;
            color: #555555;
            position: relative;
            //&::before {
            //  content: '';
            //  width: 2px;
            //  height: 22px;
            //  border-radius: 0px 0px 0px 0px;
            //  background: #6195DB;
            //  right: -100px;
            //  top: 12px;
            //}
            &.active{
              font-weight: bold;
              font-size: 24px;
              color: #333333;
              margin-top: -25px;
              &:before {
                position: absolute;
                content: url("../../assets/images/services/Ellipse.png") ;
                width: 24px;
                height: auto;
                background: transparent;
                border-radius: 0px 0px 0px 0px;
                right: 53px;
                top: 26px;
              }
            }
          }
        }
      }
      background-size: 100% 100%;
      .trademark{
        &.active3 {
          .trademark_content {
            .trademark_li {
              height: 830px;
            }
            .trademark_li:nth-of-type(1) {
              background: url("../../assets/images/services/bail.png") no-repeat;
              background-size: 100% 100%;
            }
            .trademark_li:nth-of-type(2) {
              background: url("../../assets/images/services/huangl.png") no-repeat;
              background-size: 100% 100%;
            }
            .trademark_li:nth-of-type(3) {
              background: url("../../assets/images/services/bol.png") no-repeat;
              background-size: 100% 100%;
            }
            .trademark_li:nth-of-type(4) {
              background: url("../../assets/images/services/heil.png") no-repeat;
              background-size: 100% 100%;
            }
          }
        }
        &.active4 {
          .trademark_content {
            .trademark_li:nth-of-type(1) {
              background: url("../../assets/images/services/tb.png") no-repeat;
              background-size: 100% 100%;
            }
            .trademark_li:nth-of-type(2) {
              background: url("../../assets/images/services/th.png") no-repeat;
              background-size: 100% 100%;
            }
            .trademark_li:nth-of-type(3) {
              background: url("../../assets/images/services/tbj.png") no-repeat;
              background-size: 100% 100%;
            }
            .trademark_li:nth-of-type(4) {
              background: url("../../assets/images/services/thj.png") no-repeat;
              background-size: 100% 100%;
            }
          }
        }
        //height: 560px;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        .title_name{
          font-size: 26px;
          font-weight: 500;
          color: #2E4288;
          letter-spacing: 1px;
          margin-right: 30px;
        }
        .trademark_content{
          display: flex;
          .trademark_li{
            cursor: pointer;
            width: 258px;
            height: 578px;
            /*box-shadow: 0px 2px 3px 0px rgba(0,0,0,0.12);*/
            border-radius: 12px 12px 12px 12px;
            position: relative;
            margin-right: 31px;
            text-align: center;
            .tuijian {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 95px;
              height: 30px;
              background: url("../../assets/images/services/tuijian.png") no-repeat;
              background-size: 100% 100%;
              position: absolute;
              top: -17px;
              right: 16px;
              font-weight: 400;
              font-size: 14px;
              color: #FFFFFF;
            }
            &:nth-of-type(1){
              background: url("../../assets/images/services/baiyin.png") no-repeat;
              background-size: 100% 100%;
              .trademark_content_header {
                text-align: left;
                width: 100%;
                height: 92px;
                padding: 21px 0 0 22px;
                box-sizing: border-box;
                .titles{
                  font-weight: bold;
                  font-size: 20px;
                  color: #111111;
                  display: inline-block;
                }
                p {
                  font-weight: 400;
                  font-size: 14px;
                  color: #555555;
                  span:nth-of-type(1) {
                    color: #F72929;
                    font-weight: bold;
                    font-size: 18px;
                  }
                  span:nth-of-type(2) {
                    color: #F72929;
                    font-weight: bold;
                    font-size: 16px;
                  }
                }
              }
              .payBtn {
                margin: 10px auto 0;
                width: 206px;
                height: 42px;
                background: #3B465F;
                border-radius: 8px 8px 8px 8px;
                font-weight: 500;
                font-size: 16px;
                color: #FFFFFF;
                display: flex;
                justify-content: center;
                align-items: center;
              }
              .vip{
                width: 100%;
                font-weight: 400;
                font-size: 12px;
                color: #394B75;
                height: 17px;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                margin-bottom: 16px;
                margin-top: 15px;
                .line{
                  width: 41px;
                  height: 0px;
                  display: inline-block;
                  border: 1px solid #405A94;
                  &.active:nth-of-type(1) {
                    border-image: linear-gradient(270deg, rgba(64, 90, 148, 1), rgba(153, 153, 153, 0)) 1 1;
                  }
                  &.active:nth-of-type(2) {
                    border-image: linear-gradient(90deg, rgba(64, 90, 148, 1), rgba(153, 153, 153, 0)) 1 1;
                  }
                }
                i{
                  margin: 0 9px;
                }
              }
            }
            &:nth-of-type(2){
              background: url("../../assets/images/services/huangjin.png") no-repeat;
              background-size: 100% 100%;
              .trademark_content_header {
                text-align: left;
                width: 100%;
                height: 92px;
                padding: 21px 0 0 22px;
                box-sizing: border-box;
                .titles{
                  font-weight: bold;
                  font-size: 20px;
                  color: #111111;
                  display: inline-block;
                }
                p {
                  font-weight: 400;
                  font-size: 14px;
                  color: #555555;
                  span:nth-of-type(1) {
                    color: #F72929;
                    font-weight: bold;
                    font-size: 18px;
                  }
                  span:nth-of-type(2) {
                    color: #F72929;
                    font-weight: bold;
                    font-size: 16px;
                  }
                }
                .vip{
                  width: 100%;
                  font-weight: 400;
                  font-size: 12px;
                  color: #AA6D1C;
                  height: 17px;
                  display: inline-flex;
                  align-items: center;
                  justify-content: center;
                  margin-bottom: 16px;
                  margin-top: 15px;
                  .line{
                    width: 41px;
                    height: 0px;
                    display: inline-block;
                    border: 1px solid #8F652E;
                    &.active:nth-of-type(1) {
                      border-image: linear-gradient(270deg, rgba(157, 118, 67, 1), rgba(153, 153, 153, 0)) 1 1;
                    }
                    &.active:nth-of-type(2) {
                      border-image: linear-gradient(90deg, rgba(157, 118, 67, 1), rgba(153, 153, 153, 0)) 1 1;
                    }
                  }
                  i{
                    margin: 0 9px;
                  }
                }
              }
              .payBtn {
                margin: 10px auto 0;
                width: 206px;
                height: 42px;
                background: #CD831F;
                border-radius: 8px 8px 8px 8px;
                font-weight: 500;
                font-size: 16px;
                color: #FFFFFF;
                display: flex;
                justify-content: center;
                align-items: center;
              }
              .vip{
                width: 100%;
                font-weight: 400;
                font-size: 12px;
                color: #AA6D1C;
                height: 17px;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                margin-bottom: 16px;
                margin-top: 15px;
                .line{
                  width: 41px;
                  height: 0px;
                  display: inline-block;
                  border: 1px solid #8F652E;
                  &.active:nth-of-type(1) {
                    border-image: linear-gradient(270deg, rgba(157, 118, 67, 1), rgba(153, 153, 153, 0)) 1 1;
                  }
                  &.active:nth-of-type(2) {
                    border-image: linear-gradient(90deg, rgba(157, 118, 67, 1), rgba(153, 153, 153, 0)) 1 1;
                  }
                }
                i{
                  margin: 0 9px;
                }
              }
            }
            &:nth-of-type(3){
              background: url("../../assets/images/services/bojin.png") no-repeat;
              background-size: 100% 100%;
              .trademark_content_header {
                text-align: left;
                width: 100%;
                height: 92px;
                padding: 21px 0 0 22px;
                box-sizing: border-box;
                .titles{
                  font-weight: bold;
                  font-size: 20px;
                  color: #111111;
                  display: inline-block;
                }
                p {
                  font-weight: 400;
                  font-size: 14px;
                  color: #555555;
                  span:nth-of-type(1) {
                    color: #F72929;
                    font-weight: bold;
                    font-size: 18px;
                  }
                  span:nth-of-type(2) {
                    color: #F72929;
                    font-weight: bold;
                    font-size: 16px;
                  }
                }
              }
              .payBtn {
                margin: 10px auto 0;
                width: 206px;
                height: 42px;
                background: #73351C;
                border-radius: 8px 8px 8px 8px;
                font-weight: 500;
                font-size: 16px;
                color: #FFFFFF;
                display: flex;
                justify-content: center;
                align-items: center;
              }
              .vip{
                width: 100%;
                font-weight: 400;
                font-size: 12px;
                color: #9C4E2F;
                height: 17px;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                margin-bottom: 16px;
                margin-top: 15px;
                .line{
                  width: 41px;
                  height: 0px;
                  display: inline-block;
                  border: 1px solid #AB502B;
                  &.active:nth-of-type(1) {
                    border-image: linear-gradient(270deg, rgba(157, 118, 67, 1), rgba(153, 153, 153, 0)) 1 1;
                  }
                  &.active:nth-of-type(2) {
                    border-image: linear-gradient(90deg, rgba(157, 118, 67, 1), rgba(153, 153, 153, 0)) 1 1;
                  }
                }
                i{
                  margin: 0 9px;
                }
              }
            }
            &:nth-of-type(4){
              background: url("../../assets/images/services/heizhuan.png") no-repeat;
              background-size: 100% 100%;
              margin-right: 0;
              .trademark_content_header {
                text-align: left;
                width: 100%;
                height: 92px;
                padding: 21px 0 0 22px;
                box-sizing: border-box;
                .titles{
                  font-weight: bold;
                  font-size: 20px;
                  color: #111111;
                  display: inline-block;
                }
                p {
                  font-weight: 400;
                  font-size: 14px;
                  color: #555555;
                  span:nth-of-type(1) {
                    color: #F72929;
                    font-weight: bold;
                    font-size: 18px;
                  }
                  span:nth-of-type(2) {
                    color: #F72929;
                    font-weight: bold;
                    font-size: 16px;
                  }
                }
              }
              .payBtn {
                margin: 10px auto 0;
                width: 206px;
                height: 42px;
                background: #191836;
                border-radius: 8px 8px 8px 8px;
                font-weight: 500;
                font-size: 16px;
                color: #FFFFFF;
                display: flex;
                justify-content: center;
                align-items: center;
              }
              .vip{
                width: 100%;
                font-weight: 400;
                font-size: 12px;
                color: #272553;
                height: 17px;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                margin-bottom: 16px;
                margin-top: 15px;
                .line{
                  width: 39px;
                  height: 0px;
                  display: inline-block;
                  border: 1px solid #1B1F3B;
                  &.active:nth-of-type(1) {
                    border-image: linear-gradient(270deg, rgba(25, 24, 54, 1), rgba(153, 153, 153, 0)) 1 1;
                  }
                  &.active:nth-of-type(2) {
                    border-image: linear-gradient(90deg, rgba(25, 24, 54, 1), rgba(153, 153, 153, 0)) 1 1;
                  }
                }
                i{
                  margin: 0 8px;
                }
              }
            }
            .trademark_content_item {
              padding-left: 16px;
              padding-right: 16px;
              box-sizing: border-box;
              text-align: left;
              .limt {
                padding: 0 10px;
                box-sizing: border-box;
                font-weight: 400;
                font-size: 14px;
                color: #555555;
                span {
                  font-weight: 600;
                  color: #000000;
                }
              }
              .recommend{
                width: 128px;
                height: 24px;
                background: #F7EFDD;
                border-radius: 8px 5px 5px 0px;
                font-size: 12px;
                font-weight: 400;
                color: #B68416;
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                top: 0;
                left: 0;
              }
              .grade{
                position: absolute;
                top: 11px;
                right: 15px;
              }

              .price{
                padding: 0 10px;
                box-sizing: border-box;
                margin-top: 15px;
                margin-bottom: 6px;
                box-sizing: border-box;
                font-weight: bold;
                font-size: 18px;
                color: #000000;
                display: flex;
                align-items: flex-end;
                i:nth-of-type(1) {
                  display: block;
                  font-size: 22px;
                }
                i:nth-of-type(2) {
                  margin-right: 4px;
                  position: relative;
                  bottom: 3px;
                  line-height: 1;
                  display: block;
                  margin-top: -5px;
                  font-size: 22px;
                }
                i:nth-of-type(3) {
                  display: block;
                  line-height: 1;
                  font-size: 22px;
                }
                span{
                  line-height: 1;
                  margin-left: 11px;
                  margin-right: 4px;
                  font-weight: bold;
                  font-size: 38px;
                  color: #000000;
                }
              }

              .content{
                padding: 0 3px;
                box-sizing: border-box;
                li{
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  font-weight: 400;
                  font-size: 12px;
                  color: #333333;
                  margin-bottom: 11px;
                  &:nth-last-of-type(1) {
                    margin-bottom: 0px;
                  }
                  span:nth-of-type(1) {
                    i {
                      margin-left: 3px;
                      font-weight: 600;
                      color: #000000;
                    }
                  }
                  span:nth-of-type(2) {
                    font-weight: 600;
                    color: #000000;
                  }
                }
              }
              .bottomright{
                display: none;
              }
            }
          }
        }
      }
      .custody{
        &.active4 {
          .trademark_content {
            .trademark_li:nth-of-type(1) {
              background: url("../../assets/images/services/tb.png") no-repeat;
              background-size: 100% 100%;
            }
            .trademark_li:nth-of-type(2) {
              background: url("../../assets/images/services/th.png") no-repeat;
              background-size: 100% 100%;
            }
            .trademark_li:nth-of-type(3) {
              background: url("../../assets/images/services/tbj.png") no-repeat;
              background-size: 100% 100%;
            }
            .trademark_li:nth-of-type(4) {
              background: url("../../assets/images/services/thj.png") no-repeat;
              background-size: 100% 100%;
            }
          }
        }
        //height: 560px;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        .title_name{
          font-size: 26px;
          font-weight: 500;
          color: #2E4288;
          letter-spacing: 1px;
          margin-right: 30px;
        }
        .trademark_content{
          display: flex;
          .trademark_li{
            cursor: pointer;
            width: 258px;
            height: 416px;
            /*box-shadow: 0px 2px 3px 0px rgba(0,0,0,0.12);*/
            border-radius: 12px 12px 12px 12px;
            position: relative;
            margin-right: 31px;
            text-align: center;
            .tuijian {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 95px;
              height: 30px;
              background: url("../../assets/images/services/tuijian.png") no-repeat;
              background-size: 100% 100%;
              position: absolute;
              top: -17px;
              right: 16px;
              font-weight: 400;
              font-size: 14px;
              color: #FFFFFF;
            }
            &:nth-of-type(1){
              background: url("../../assets/images/services/baiyin.png") no-repeat;
              background-size: 100% 100%;
              .trademark_content_header {
                display: flex;
                align-items: center;
                text-align: left;
                width: 100%;
                height: 86px;
                padding-left: 24px;
                box-sizing: border-box;
                .titles{
                  font-weight: bold;
                  font-size: 20px;
                  color: #111111;
                  display: inline-block;
                }
              }
              .payBtn {
                margin: 10px auto 0;
                width: 206px;
                height: 42px;
                background: #3B465F;
                border-radius: 8px 8px 8px 8px;
                font-weight: 500;
                font-size: 16px;
                color: #FFFFFF;
                display: flex;
                justify-content: center;
                align-items: center;
              }
              .vip{
                width: 100%;
                font-weight: 400;
                font-size: 12px;
                color: #394B75;
                height: 17px;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                margin-bottom: 16px;
                margin-top: 15px;
                .line{
                  width: 80px;
                  height: 0px;
                  display: inline-block;
                  border: 1px solid #405A94;
                  &.active:nth-of-type(1) {
                    border-image: linear-gradient(270deg, rgba(64, 90, 148, 1), rgba(153, 153, 153, 0)) 1 1;
                  }
                  &.active:nth-of-type(2) {
                    border-image: linear-gradient(90deg, rgba(64, 90, 148, 1), rgba(153, 153, 153, 0)) 1 1;
                  }
                }
                i{
                  margin: 0 9px;
                }
              }
            }
            &:nth-of-type(2){
              background: url("../../assets/images/services/huangjin.png") no-repeat;
              background-size: 100% 100%;
              .trademark_content_header {
                display: flex;
                align-items: center;
                text-align: left;
                width: 100%;
                height: 86px;
                padding-left: 24px;
                box-sizing: border-box;
                .titles{
                  font-weight: bold;
                  font-size: 20px;
                  color: #111111;
                  display: inline-block;
                }
                .vip{
                  width: 100%;
                  font-weight: 400;
                  font-size: 12px;
                  color: #AA6D1C;
                  height: 17px;
                  display: inline-flex;
                  align-items: center;
                  justify-content: center;
                  margin-bottom: 16px;
                  margin-top: 15px;
                  .line{
                    width: 80px;
                    height: 0px;
                    display: inline-block;
                    border: 1px solid #8F652E;
                    &.active:nth-of-type(1) {
                      border-image: linear-gradient(270deg, rgba(157, 118, 67, 1), rgba(153, 153, 153, 0)) 1 1;
                    }
                    &.active:nth-of-type(2) {
                      border-image: linear-gradient(90deg, rgba(157, 118, 67, 1), rgba(153, 153, 153, 0)) 1 1;
                    }
                  }
                  i{
                    margin: 0 9px;
                  }
                }
              }
              .payBtn {
                margin: 10px auto 0;
                width: 206px;
                height: 42px;
                background: #CD831F;
                border-radius: 8px 8px 8px 8px;
                font-weight: 500;
                font-size: 16px;
                color: #FFFFFF;
                display: flex;
                justify-content: center;
                align-items: center;
              }
              .vip{
                width: 100%;
                font-weight: 400;
                font-size: 12px;
                color: #AA6D1C;
                height: 17px;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                margin-bottom: 16px;
                margin-top: 15px;
                .line{
                  width: 80px;
                  height: 0px;
                  display: inline-block;
                  border: 1px solid #8F652E;
                  &.active:nth-of-type(1) {
                    border-image: linear-gradient(270deg, rgba(157, 118, 67, 1), rgba(153, 153, 153, 0)) 1 1;
                  }
                  &.active:nth-of-type(2) {
                    border-image: linear-gradient(90deg, rgba(157, 118, 67, 1), rgba(153, 153, 153, 0)) 1 1;
                  }
                }
                i{
                  margin: 0 9px;
                }
              }
            }
            &:nth-of-type(3){
              background: url("../../assets/images/services/bojin.png") no-repeat;
              background-size: 100% 100%;
              .trademark_content_header {
                display: flex;
                align-items: center;
                text-align: left;
                width: 100%;
                height: 86px;
                padding-left: 24px;
                box-sizing: border-box;
                .titles{
                  font-weight: bold;
                  font-size: 20px;
                  color: #111111;
                  display: inline-block;
                }
              }
              .payBtn {
                margin: 10px auto 0;
                width: 206px;
                height: 42px;
                background: #73351C;
                border-radius: 8px 8px 8px 8px;
                font-weight: 500;
                font-size: 16px;
                color: #FFFFFF;
                display: flex;
                justify-content: center;
                align-items: center;
              }
              .vip{
                width: 100%;
                font-weight: 400;
                font-size: 12px;
                color: #9C4E2F;
                height: 17px;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                margin-bottom: 16px;
                margin-top: 15px;
                .line{
                  width: 80px;
                  height: 0px;
                  display: inline-block;
                  border: 1px solid #AB502B;
                  &.active:nth-of-type(1) {
                    border-image: linear-gradient(270deg, rgba(157, 118, 67, 1), rgba(153, 153, 153, 0)) 1 1;
                  }
                  &.active:nth-of-type(2) {
                    border-image: linear-gradient(90deg, rgba(157, 118, 67, 1), rgba(153, 153, 153, 0)) 1 1;
                  }
                }
                i{
                  margin: 0 9px;
                }
              }
            }
            &:nth-of-type(4){
              background: url("../../assets/images/services/heizhuan.png") no-repeat;
              background-size: 100% 100%;
              margin-right: 0;
              .trademark_content_header {
                display: flex;
                align-items: center;
                text-align: left;
                width: 100%;
                height: 86px;
                padding-left: 24px;
                box-sizing: border-box;
                .titles{
                  font-weight: bold;
                  font-size: 20px;
                  color: #111111;
                  display: inline-block;
                }
              }
              .payBtn {
                margin: 10px auto 0;
                width: 206px;
                height: 42px;
                background: #191836;
                border-radius: 8px 8px 8px 8px;
                font-weight: 500;
                font-size: 16px;
                color: #FFFFFF;
                display: flex;
                justify-content: center;
                align-items: center;
              }
              .vip{
                width: 100%;
                font-weight: 400;
                font-size: 12px;
                color: #272553;
                height: 17px;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                margin-bottom: 16px;
                margin-top: 15px;
                .line{
                  width: 80px;
                  height: 0px;
                  display: inline-block;
                  border: 1px solid #1B1F3B;
                  &.active:nth-of-type(1) {
                    border-image: linear-gradient(270deg, rgba(25, 24, 54, 1), rgba(153, 153, 153, 0)) 1 1;
                  }
                  &.active:nth-of-type(2) {
                    border-image: linear-gradient(90deg, rgba(25, 24, 54, 1), rgba(153, 153, 153, 0)) 1 1;
                  }
                }
                i{
                  margin: 0 8px;
                }
              }
            }
            .trademark_content_item {
              padding-left: 16px;
              padding-right: 16px;
              box-sizing: border-box;
              text-align: left;
              .limt {
                padding: 0 10px;
                box-sizing: border-box;
                font-weight: 400;
                font-size: 14px;
                color: #555555;
                span {
                  font-weight: 600;
                  color: #000000;
                }
              }
              .recommend{
                width: 128px;
                height: 24px;
                background: #F7EFDD;
                border-radius: 8px 5px 5px 0px;
                font-size: 12px;
                font-weight: 400;
                color: #B68416;
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                top: 0;
                left: 0;
              }
              .grade{
                position: absolute;
                top: 11px;
                right: 15px;
              }

              .price{
                padding: 0 10px;
                box-sizing: border-box;
                margin-top: 15px;
                margin-bottom: 6px;
                box-sizing: border-box;
                font-weight: bold;
                font-size: 18px;
                color: #000000;
                display: flex;
                align-items: flex-end;
                i:nth-of-type(1) {
                  display: block;
                  font-size: 22px;
                }
                i:nth-of-type(2) {
                  margin-right: 4px;
                  position: relative;
                  bottom: 3px;
                  line-height: 1;
                  display: block;
                  margin-top: -5px;
                  font-size: 22px;
                }
                i:nth-of-type(3) {
                  display: block;
                  line-height: 1;
                  font-size: 22px;
                }
                span{
                  line-height: 1;
                  margin-left: 11px;
                  margin-right: 4px;
                  font-weight: bold;
                  font-size: 38px;
                  color: #000000;
                }
              }

              .content{
                padding: 0 3px;
                box-sizing: border-box;
                li{
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  font-weight: 400;
                  font-size: 12px;
                  color: #333333;
                  margin-bottom: 15px;
                  &:nth-last-of-type(1) {
                    margin-bottom: 30px;
                  }
                  span:nth-of-type(1) {
                    i {
                      margin-left: 3px;
                      font-weight: 600;
                      color: #000000;
                    }
                  }
                  span:nth-of-type(2) {
                    font-weight: 600;
                    color: #000000;
                  }
                }
              }
              .bottomright{
                display: none;
              }
            }
          }
        }
      }
      .patent{
        margin: 38px auto 0;
        width: 1200px;
        height: 470px;
        background: linear-gradient( 346deg, #F3F6FF 0%, #DCDCF7 100%);
        border-radius: 8px;
        display: flex;
        align-items: center;
        padding: 0 0 0 29px;
        .title_name{
          font-size: 26px;
          font-weight: 500;
          color: #2E4288;
          letter-spacing: 1px;
          margin-right: 30px;
        }
        .trademark_content{
          display: flex;
          .trademark_li{
            cursor: pointer;
            width: 235px;
            height: 396px;
            overflow: hidden;
            background: #FFFFFF;
            position: relative;
            margin-right: 40px;
            box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.1800);
            border-radius: 8px;
            text-align: center;
            .recommend{
              width: 128px;
              height: 24px;
              background: #F7EFDD;
              border-radius: 8px 5px 5px 0px;
              font-size: 12px;
              font-weight: 400;
              color: #B68416;
              display: flex;
              align-items: center;
              justify-content: center;
              position: absolute;
              top: 0;
              left: 0;
            }
            .grade{
              position: absolute;
              top: 11px;
              right: 15px;
            }
            .titles{
              font-size: 18px;
              font-weight: 500;
              display: inline-block;
              color: #000000;
              font-weight: 500;
              margin: 32px 0 0px 0;
            }
            .line_{
              width: 202px;
              height: 1px;
              display: inline-block;
              background: rgba(46,66,136,0.48);
            }
            .originalPrice{
              font-size: 14px;
              font-weight: 400;
              color: #B98269;
              margin-left: -95px;
              margin-top: 4px;
              span{
                text-decoration: line-through;
                margin-left: 2px;
              }
            }
            .price{
              margin-top: 15px;
              margin-bottom: 6px;
              box-sizing: border-box;
              font-weight: bold;
              font-size: 18px;
              color: #000000;
              display: flex;
              align-items: flex-end;
              i:nth-of-type(1) {
                display: block;
                font-size: 22px;
              }
              i:nth-of-type(2) {
                margin-right: 4px;
                position: relative;
                bottom: 3px;
                line-height: 1;
                display: block;
                margin-top: -5px;
                font-size: 22px;
              }
              i:nth-of-type(3) {
                display: block;
                line-height: 1;
                font-size: 22px;
              }
              span{
                line-height: 1;
                margin-left: 11px;
                margin-right: 4px;
                font-weight: bold;
                font-size: 38px;
                color: #000000;
              }
            }
            .vip{
              font-size: 12px;
              font-weight: 400;
              color: #555555;
              height: 17px;
              display: inline-flex;
              align-items: center;
              margin-bottom: 16px;
              margin-top: 15px;
              .line{
                width: 41px;
                height: 1px;
                display: inline-block;
                background:  #EBE9E9;
              }
              i{
                margin: 0 9px;
              }
            }
            .content{
              li{
                display: flex;
                justify-content: space-between;
                padding: 0 33px 15px 34px;
                align-items: center;
                img{
                  width: 14px;
                  height: 10px;
                }
                font-size: 14px;
                font-weight: 400;
                color: #666666;
                &.err {
                  img{
                    width: 12px;
                    height: 12px;
                  }
                  font-size: 14px;
                  font-weight: 400;
                  color: #C3C2C2;
                }
              }
            }
            .bottomright{
              display: none;
            }
          }
          .trademark_li.active {
            box-shadow: 0px 0px 4px 2px rgba(16,36,106,0.1800);
            border: 1px solid #2E54D9;
            .bottomright{
              display: block;
              position: absolute;
              bottom: -35px;
              right: -41px;
            }
          }
          .trademark_li:hover {
            box-shadow: 0px 0px 4px 2px rgba(16,36,106,0.1800);
            border: 1px solid #2E54D9;
            .bottomright{
              display: block;
              position: absolute;
              bottom: -35px;
              right: -41px;
            }
          }
        }
      }
      .monitor{
        margin: 38px auto 0;
        width: 1200px;
        height: 530px;
        background: linear-gradient( 346deg, #F3F6FF 0%, #DCDCF7 100%);
        border-radius: 8px;
        display: flex;
        align-items: center;
        padding: 0 0 0 29px;
        .monitor_item{
          font-size: 12px;
          i{
            color: red;
            font-size: 16px;
          }
        }
        .title_name{
          font-size: 26px;
          font-weight: 500;
          color: #2E4288;
          letter-spacing: 1px;
          margin-right: 30px;
        }
        .trademark_content{
          display: flex;
          .trademark_li{
            cursor: pointer;
            width: 235px;
            height: 455px;
            overflow: hidden;
            background: #FFFFFF;
            position: relative;
            margin-right: 40px;
            box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.1800);
            border-radius: 8px;
            text-align: center;
            .recommend{
              width: 128px;
              height: 24px;
              background: #F7EFDD;
              border-radius: 8px 5px 5px 0px;
              font-size: 12px;
              font-weight: 400;
              color: #B68416;
              display: flex;
              align-items: center;
              justify-content: center;
              position: absolute;
              top: 0;
              left: 0;
            }
            .grade{
              position: absolute;
              top: 11px;
              right: 15px;
            }
            .titles{
              font-size: 18px;
              font-weight: 500;
              display: inline-block;
              color: #000000;
              font-weight: 500;
              margin: 32px 0 0px 0;
            }
            .line_{
              width: 202px;
              height: 1px;
              display: inline-block;
              background: rgba(46,66,136,0.48);
            }
            .originalPrice{
              font-size: 14px;
              font-weight: 400;
              color: #B98269;
              margin-left: -95px;
              margin-top: 4px;
              span{
                text-decoration: line-through;
                margin-left: 2px;
              }
            }
            .price{
              margin-top: 15px;
              margin-bottom: 6px;
              box-sizing: border-box;
              font-weight: bold;
              font-size: 18px;
              color: #000000;
              display: flex;
              align-items: flex-end;
              i:nth-of-type(1) {
                display: block;
                font-size: 22px;
              }
              i:nth-of-type(2) {
                margin-right: 4px;
                position: relative;
                bottom: 3px;
                line-height: 1;
                display: block;
                margin-top: -5px;
                font-size: 22px;
              }
              i:nth-of-type(3) {
                display: block;
                line-height: 1;
                font-size: 22px;
              }
              span{
                line-height: 1;
                margin-left: 11px;
                margin-right: 4px;
                font-weight: bold;
                font-size: 38px;
                color: #000000;
              }
            }
            .vip{
              font-size: 12px;
              font-weight: 400;
              color: #555555;
              height: 17px;
              display: inline-flex;
              align-items: center;
              margin-bottom: 16px;
              margin-top: 15px;
              .line{
                width: 41px;
                height: 1px;
                display: inline-block;
                background:  #EBE9E9;
              }
              i{
                margin: 0 9px;
              }
            }
            .content{
              li{
                display: flex;
                justify-content: space-between;
                padding: 0 33px 15px 34px;
                align-items: center;
                img{
                  width: 14px;
                  height: 10px;
                }
                font-size: 14px;
                font-weight: 400;
                color: #666666;
                &.err {
                  img{
                    width: 12px;
                    height: 12px;
                  }
                  font-size: 14px;
                  font-weight: 400;
                  color: #C3C2C2;
                }
              }
            }
            .bottomright{
              display: none;
            }
          }
          .trademark_li.active {
            box-shadow: 0px 0px 4px 2px rgba(16,36,106,0.1800);
            border: 1px solid #2E54D9;
            .bottomright{
              display: block;
              position: absolute;
              bottom: -35px;
              right: -41px;
            }
          }
          .trademark_li:hover {
            box-shadow: 0px 0px 4px 2px rgba(16,36,106,0.1800);
            border: 1px solid #2E54D9;
            .bottomright{
              display: block;
              position: absolute;
              bottom: -35px;
              right: -41px;
            }
          }
        }
      }
      .free{
        margin: 38px auto 0;
        width: 1200px;
        height: 470px;
        background: linear-gradient(351deg, #F3F6FF 0%, #E4EFFF 100%);
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 0 0 29px;
        .title_name{
          font-size: 26px;
          font-weight: 500;
          color: #2E4288;
          letter-spacing: 1px;
          margin-right: 30px;
        }
        .trademark_content{
          display: flex;
          .trademark_li{
            cursor: pointer;
            width: 235px;
            height: 445px;
            overflow: hidden;
            background: #FFFFFF;
            position: relative;
            margin-right: 40px;
            box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.1800);
            border-radius: 8px;
            text-align: center;
            .recommend{
              width: 128px;
              height: 24px;
              background: #F7EFDD;
              border-radius: 8px 5px 5px 0px;
              font-size: 12px;
              font-weight: 400;
              color: #B68416;
              display: flex;
              align-items: center;
              justify-content: center;
              position: absolute;
              top: 0;
              left: 0;
            }
            .grade{
              position: absolute;
              top: 11px;
              right: 15px;
            }
            .titles{
              font-size: 18px;
              font-weight: 500;
              display: inline-block;
              color: #000000;
              font-weight: 500;
              margin: 23px 0 0px 0;
            }
            .line_{
              width: 202px;
              height: 1px;
              display: inline-block;
              background: rgba(46,66,136,0.48);
            }
            .originalPrice{
              font-size: 14px;
              font-weight: 400;
              color: #B98269;
              margin-left: -95px;
              margin-top: 4px;
              span{
                text-decoration: line-through;
                margin-left: 2px;
              }
            }
            .price{
              margin-top: 15px;
              margin-bottom: 6px;
              box-sizing: border-box;
              font-weight: bold;
              font-size: 18px;
              color: #000000;
              display: flex;
              align-items: flex-end;
              i:nth-of-type(1) {
                display: block;
                font-size: 22px;
              }
              i:nth-of-type(2) {
                margin-right: 4px;
                position: relative;
                bottom: 3px;
                line-height: 1;
                display: block;
                margin-top: -5px;
                font-size: 22px;
              }
              i:nth-of-type(3) {
                display: block;
                line-height: 1;
                font-size: 22px;
              }
              span{
                line-height: 1;
                margin-left: 11px;
                margin-right: 4px;
                font-weight: bold;
                font-size: 38px;
                color: #000000;
              }
            }
            .vip{
              font-size: 12px;
              font-weight: 400;
              color: #555555;
              height: 17px;
              display: inline-flex;
              align-items: center;
              margin-bottom: 19px;
              margin-top: 15px;
              .line{
                width: 41px;
                height: 1px;
                display: inline-block;
                background:  #EBE9E9;
              }
              i{
                margin: 0 9px;
              }
            }
            .content{
              li{
                display: flex;
                justify-content: space-between;
                padding: 0 33px 11px 34px;
                align-items: center;
                img{
                  width: 14px;
                  height: 10px;
                }
                font-size: 14px;
                font-weight: 400;
                color: #666666;
                &.err {
                  img{
                    width: 12px;
                    height: 12px;
                  }
                  font-size: 14px;
                  font-weight: 400;
                  color: #C3C2C2;
                }
              }
            }
            .bottomright{
              display: none;
            }
          }
          .trademark_li.active {
            box-shadow: 0px 0px 4px 2px rgba(16,36,106,0.1800);
            border: 1px solid #2E54D9;
            .bottomright{
              display: block;
              position: absolute;
              bottom: -35px;
              right: -41px;
            }
          }
          .trademark_li:hover {
            box-shadow: 0px 0px 4px 2px rgba(16,36,106,0.1800);
            border: 1px solid #2E54D9;
            .bottomright{
              display: block;
              position: absolute;
              bottom: -35px;
              right: -41px;
            }
          }
        }
        .freeBtn{
          &.is-disabled, .el-button--primary.is-disabled:active, .el-button--primary.is-disabled:focus, .el-button--primary.is-disabled:hover {
            color: #fff!important;
            background-color: #a0cfff!important;
            border-color: #a0cfff!important;
          }
          /*color: #FFFFFF;*/
        }
      }

    }
  }

  .equityComparison {
    width: 1200px;
    position: relative;
    top: -35px;
    .equityComparison_title {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      margin-bottom: 36px;
      span {
        font-weight: bold;
        font-size: 20px;
        color: #000000;
        margin-right: 8px;
      }
      i {
        font-size: 16px;
        color: #000000;;
      }

    }
    /deep/.vxe-grid {
      border-radius: 10px 10px 0 0;
      overflow: hidden;
      .vxe_equityComparison_header {
        height: 69px;
        background: #D5E4FF;
        th:nth-of-type(1) {
          background: #ACB8CE;
        }
        .vxe-cell--title {
          font-weight: 600;
          font-size: 16px;
          color: #000000;
        }
      }
      .vxe-body--row {
        height: 53px;
      }
      .vxe-cell {
        padding-left: 38px;
        box-sizing: border-box;
        font-weight: 600;
        font-size: 14px;
        color: #555555;
      }

      .color-row1-1 {
        background: #E4EBF7;
        span {
          font-size: 15px;
          color: #333333;
        }
      }

      .row--hover {
        background: #FFFFFF!important;
        box-shadow: 0px 0px 4px 3px rgba(10,35,78,0.18)!important;
        border-radius: 8px 8px 8px 8px;
        .color-row1-1 {
          background: #FFFFFF!important;
        }
      }
    }

  }

  .equityComparisonful {
    width: 1200px;
    position: relative;
    top: -69px;
    .equityComparison_title {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      margin-bottom: 36px;
      span {
        font-weight: bold;
        font-size: 20px;
        color: #000000;
        margin-right: 8px;
      }
      i {
        font-size: 16px;
        color: #000000;;
      }

    }
    /deep/.vxe-grid {
      border-radius: 10px 10px 0 0;
      overflow: hidden;
      .expand {
        padding: 13px 87px;
        box-sizing: border-box;
        height: 74px;
        background: #BCCCE8;
        span:nth-of-type(1) {
          font-weight: 600;
          font-size: 14px;
          color: #555555;
          margin-right: 140px;
        }
        span:nth-of-type(2) {
          font-weight: 600;
          font-size: 14px;
          color: #073168;
        }
      }
      .vxe_equityComparison_header {
        height: 69px;
        background: #DBEBFF;
        .vxe-header--column {
          .is--line:before {
            background-color: transparent;
          }
        }
        th:nth-of-type(1) {
           background: #ACB8CE;
         }
        th:nth-of-type(2) {
          background: #ACB8CE;
        }
        .vxe-cell--title {
          font-weight: 600;
          font-size: 16px;
          color: #000000;
        }
      }
      .vxe-body--row {
        height: 53px;
        background: #D5E4FF;
      }
      .vxe-cell {
        padding-left: 38px;
        box-sizing: border-box;
        font-weight: 600;
        font-size: 14px;
        color: #555555;
      }

      .color-row1-s {
        .vxe-cell {
          padding-left: 0px!important;
        }
      }
      .col--checkbox {
        .vxe-cell {
          padding-left: 20px;
          .vxe-checkbox--icon {
            color: #ffffff;
            background: #FFFFFF;
            border-radius: 3px 3px 3px 3px;
            border: 1px solid #A2B1CB;
            &.vxe-icon-checkbox-checked-fill {
              color: #0061E3;
            }
          }
        }
      }
      .color-row1-1 {
        background: #E4EBF7;
      }
      .row--hover {
        background: #FFFFFF!important;
        box-shadow: 0px 0px 4px 3px rgba(10,35,78,0.18)!important;
        border-radius: 8px 8px 8px 8px;
        .color-row1-1 {
          background: #FFFFFF!important;
        }
      }
    }

  }

  .pay{
    z-index: 100;
    width: 100%;
    height: 225px;
    background: white;
    background: url("../../assets/images/services/paybg.png") no-repeat 100% 100%;
    position: sticky;
    bottom: 0;
    .pay_cen{
      width: 1200px;
      height: 225px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      padding: 20px 0 0 70px;
      .explain{
        line-height: 20px;
        margin-left: 13px;
        margin-right: 220px;
        position: relative;
        img {
          transform: rotate(-30);
          position: absolute;
          top: -53px;
          left: -48px;
        }
        p {
          font-weight: 600;
          font-size: 20px;
          color: #000000;
          span{
            margin-left: 5px;
            color: #B1492A;
            font-size: 24px;
          }
        }
      }
      .payPrice{
        p:nth-of-type(1){
          margin-bottom: 5px;
          text-align: right;
          font-weight: 600;
          font-size: 14px;
          color: #2E2CA0;
          span{
            margin-left: 26px;
            font-size: 26px;
          }
        }
        p:nth-of-type(2){
          text-align: right;
          font-weight: 600;
          font-size: 20px;
          color: #F74C18;
          span{
            font-size: 32px;
          }
        }
      }
      .toPay{
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        width: 168px;
        height: 42px;
        border-radius: 8px 8px 8px 8px;
        font-weight: 500;
        font-size: 16px;
        color: #FFFFFF;
        margin-left: 133px;
        &.kai {
          margin-top: 30px;
          background: #2E54D9;
          margin-bottom: 17px;
        }
        &.yuan {
          background: #191836;
        }
      }
    }
  }
  /*@media screen and (max-width: 1600px) {*/
  /*  .index_wrap {*/
  /*    .index_banner {*/
  /*      height: 460px;*/
  /*      .index_banner_title {*/
  /*        !*top: 150px;*!*/
  /*        !*left: 133px;*!*/
  /*      }*/
  /*    }*/

  /*    .learnMore{*/
  /*      margin-top: 110px!important;*/
  /*    }*/
  /*  }*/
  /*}*/

  /*@media screen and (max-width: 1400px) {*/
  /*  .index_wrap {*/
  /*    .index_banner {*/
  /*      height: 602px;*/
  /*      .index_banner_title {*/
  /*        !*top: 210px;*!*/
  /*        !*left: 34px;*!*/
  /*      }*/
  /*    }*/
  /*  }*/
  /*}*/

  /*.arrow-enter {*/
  /*  transform: translateX(-30px);*/
  /*}*/
  /*.arrow-enter-active {*/
  /*  transition: all 0.8s ease;*/
  /*}*/
</style>
<style lang="less">
  .account_dialog {
    width: 600px;
    height: 740px;
    background-color: #ffffff;
    box-shadow: 0px 6px 48px 0px rgba(128, 128, 128, 0.16);
    border-radius: 5px;
    .el-dialog__header,
    .el-dialog__body {
      padding: 0;
    }
    .info_wrap {
      .info_con {
        padding: 45px 80px 80px;
      }
    }
  }

  .login_dialog {
    width: 683px;
    height: 430px;
    //   height: 500px;
    border-radius: 5px;
    /*padding: 10px 5px!important;*/
    box-shadow: 0px 6px 48px 0px rgba(128, 128, 128, 0.16);

    .el-dialog__header,
    .el-dialog__body {
      padding: 0;
    }
  }
</style>
